<template>
  <div class="mainnbox">
    <div class="box_1">
      <div class="left_pic"></div>
      <div class="right_box">
        <span class="sp1">玩家驿站，畅玩全球</span>
        <span class="sp2">千款全球精品游戏 / 低至一折 / 云端存档</span>
        <div class="down_btn_box">
          <!-- <div class="down_btn" @click="down_fun"></div> -->
          <div class="down_btn" @click="downloadFile"></div>
          <div class="free"></div>
        </div>
      </div>
    </div>
    <div class="box_2">
      <div class="d_box">
        <div class="pic1"></div>
        <div class="p_sp">游戏多</div>
        <div class="p_sp1">玩家驿站汇集全球上千款游戏一站 收录一键启动</div>
      </div>
      <div class="d_box">
        <div class="pic2"></div>
        <div class="p_sp">价格低</div>
        <div class="p_sp1">
          全球游戏超低价体验，成为玩家驿站 大会员还可免费体验千款3A大作
        </div>
      </div>
      <div class="d_box">
        <div class="pic3"></div>
        <div class="p_sp">够安全</div>
        <div class="p_sp1">
          客户端及游戏均通过国内安全机构 检测，下载、游戏更安全
        </div>
      </div>
      <div class="d_box">
        <div class="pic4"></div>
        <div class="p_sp">云存档</div>
        <div class="p_sp1">游戏存档跟随账号远程保存玩游戏 记录不丢失</div>
      </div>
    </div>
  </div>
</template>

<script>
import { addDown } from "@/service/api";
export default {
  data() {
    return {};
  },
  methods: {
    downloadFile() {
      if (this.$store.state.channel_code == "") {
        let url = this.$store.state.downfile.url; //主站
        //  let url = this.$store.state.downfile_main_bd.url;//百度推广的
        // let url = this.$store.state.downfile_main_360.url;

        const fileUrl = url;
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "this.$store.state.downfile.filename");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // this.addDownfun(0);//百度
        // this.addDownfun(1);//360
      }
      else{
        //渠道
        console.log(this.$store.state.channel_code);
        let d_index = this.$store.state.downfile_channel_list.findIndex(
          (item) => item.code == this.$store.state.channel_code
        );
        let url = this.$store.state.downfile.url; //主站
        if (d_index != -1) {
          //没有查到 默认主站
          url = this.$store.state.downfile_channel_list[d_index].url;
        }
        const fileUrl = url;
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "this.$store.state.downfile.filename");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
       this.addDownfun_channel(this.$store.state.downfile_channel_list[d_index].down_code);//打点
      }
    },
     async addDownfun_channel(val) {
      const res = await addDown(val);
      if (res.code == 200) {
      } else {
      }
    },
    async addDownfun(val) {
      let gameName = "";
      if (val == 0) {
        gameName = "百度_推广首页";
      } else {
        gameName = "360_推广首页";
      }
      let p = {
        type: 2,
        gameName: gameName,
      };
      const res = await addDown(p);
      if (res.code == 200) {
      } else {
      }
    },
  },
};
</script>
<style scoped lang="less">
.mainnbox {
  width: 100%px;
  height: 830px;
  margin: 0 auto;
  background-color: #1a1a1a;
  .box_1 {
    margin: 0 auto;
    width: 1250px;
    height: 450px;
    display: flex;
    .left_pic {
      margin-top: 48px;
      width: 608px;
      height: 402px;
      background: url(~@/assets/images/new/left_pic.png);
    }
    .right_box {
      margin-top: 48px;
      margin-left: 71px;
      width: 400px;
      height: 400px;
      //background-color: yellow;
      display: flex;
      flex-direction: column;

      .sp1 {
        margin-top: 100px;
        color: #5dfe97;
        font-size: 36px;
        font-weight: bold;
        text-align: start;
      }
      .sp2 {
        margin-top: 11px;
        color: #ffffff;
        font-size: 22px;
        text-align: start;
      }
      .down_btn_box {
        margin-top: 25px;
        width: 100%;
        height: 70px;
        //border:1px solid #ffffff;
        display: flex;
        .down_btn {
          margin-top: 21px;
          width: 191px;
          height: 49px;
          background: url(~@/assets/images/new/down_btn.png);
          cursor: pointer;
          //border:1px solid #ffffff;
        }
        .free {
          width: 67px;
          height: 24px;
          background: url(~@/assets/images/new/free.png);
          margin-left: -50px;
          margin-top: 10px;
        }
      }

      .down_btn:hover {
        background-position: -203px 0;
      }
    }
  }
  .box_2 {
    width: 1250px;
    height: 200px;
    margin: 0 auto;
    margin-top: 30px;
    //background-color: green;
    display: flex;
    justify-content: space-between;
    .d_box {
      width: 210px;
      height: 100%;
      //border: 1px solid #ff0000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .pic1 {
        width: 90px;
        height: 68px;
        background: url(~@/assets/images/new/1.png);
      }
      .pic2 {
        width: 78px;
        height: 68px;
        background: url(~@/assets/images/new/2.png);
      }
      .pic3 {
        width: 75px;
        height: 68px;
        background: url(~@/assets/images/new/3.png);
      }
      .pic4 {
        width: 75px;
        height: 68px;
        background: url(~@/assets/images/new/4.png);
      }
      .p_sp {
        color: #5dfe97;
        font-size: 20px;
      }
      .p_sp1 {
        color: #c6c6c6;
        font-size: 14px;
      }
    }
  }
}
</style>
