import axios from "axios"

/* axios拦截器 */
//请求拦截
const gg_url_1="https://api.steamboxs.com/api/getProductDetail"
const gg_url_2="https://gg-api.wanjiayizhan.com/api/order/v1/product"
const gg_url_3="https://gg-api.wanjiayizhan.com/api/order/v1/create_order"
const gg_url_4="https://gg-api.wanjiayizhan.com/api/order/v1/order_status"




axios.interceptors.request.use(
  config => {
    /* showLoading() */
    //获取到token
    if (localStorage.getItem("token")) {
      //添加请求头
      let t_token = localStorage.getItem("token")
      if(config.url==gg_url_1 || config.url==gg_url_2){
        if (t_token.substring(0, 3) == "gw:") {
          t_token = t_token.slice(3);
        }
        config.headers.Authorization = t_token
      }
      else if(config.url==gg_url_3 || config.url==gg_url_4){
        if (t_token.substring(0, 3) == "gw:") {
          t_token = t_token.slice(3);
        }
        config.headers.token = t_token
      }
      else{
        // config.headers.token=""
        config.headers.Authorization = t_token
      }
      
    }
    return config
  },
  error => Promise.reject(error)
)

//响应拦截
axios.interceptors.response.use(
  response => {
    
    return response.data
  },
  error => {
    if (error.response.status == 401) {
    }
   
    Promise.reject(error)
  }
)

export const configs = config => axios(config)
