<template>
  <div class="box">
      <div class="f_box">
         <iframe class="iframe_box" ref="iframe" :src=this.$store.state.global_url frameborder="0" scolling="no">
         </iframe>
      </div>
      <!-- <div class="dia_s" v-show="this.$store.state.dialogVisible"   >
      <p>
        玩家驿站是成都精诚绿洲网络科技有限公司开发的专业的正版游戏销售平台，用户可在平台内，购买游戏账号、CDK或游戏周边产品，如用户购买的是共享服务，可直接通过平台启动游戏。平台售卖游戏包括Steam、Uplay、Origin、战网等海外网游联机平台游戏。目前，玩家驿站已经为百万玩家提供服务。作为一家专业的游戏综合服务商，玩家驿站致力于重塑并优化产业生态及服务形式，专注研发行业应用和链接业内游戏资源。玩家驿站会不断进化，提供更有竞争力的服务，为所有热爱游戏的玩家带来更爽快的体验
      </p>
      </div> -->
  </div>
</template>

<script>

import { addmoney } from "@/service/api";
export default {
  components: {
      
   },
  data() {
    return {
         
    };
  },
  created(){
    
     },

  methods:{
   
       
  },
}
</script>
<style scoped lang='less'>
.box{
    background-color: #1a1a1a;
    width: 100%;
     height: 830px;
    margin: 0 auto;
    .f_box{
         width: 1280px;
         height: 100%;
        //  background-color: yellow;
         margin: 0 auto;
         .iframe_box{
           width: 100%;
            height: 100%;
         }
    }
}
.dia_s{
  position: absolute;
  top:200px;
  width: 500px;
  height: 500px;
  background-color: #1a1a1a;
  left: 600px;
  font-size: 25px;
  color: #fff;
  p{
    top:10px;
  }
}
</style>
