import { render, staticRenderFns } from "./pubg.vue?vue&type=template&id=0e653810&scoped=true&"
import script from "./pubg.vue?vue&type=script&lang=js&"
export * from "./pubg.vue?vue&type=script&lang=js&"
import style0 from "./pubg.vue?vue&type=style&index=0&id=0e653810&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e653810",
  null
  
)

export default component.exports