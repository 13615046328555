<template>
  <div class="buywindow" v-show="$store.state.buywindow">
    <div class="top">
      <div class="top_title">确定订单</div>
      <div @click="closefn" class="top_close"></div>
    </div>
    <div class="item_box">
      <div class="item_pic">
        <el-image
          style="width: 385px; height: 226px"
          :src="require('@/assets/images/bd/' + gameinfo.pic + '')"
          fit="cover"
        ></el-image>
      </div>
      <div class="item_info">
        <div class="item_info_1">
          <div class="item_name">{{ gameinfo.name }}</div>
          <div class="price_box">
            <span class="s1">￥</span>
            <span class="s2">{{ gameinfo.price }}</span>
          </div>
        </div>
        <div class="item_info_2">
          <div class="item_info_2_bb">
            <div class="bb_text">版本</div>
            <div class="bb">{{ gameinfo.banben }}</div>
          </div>
          <div class="item_info_2_lx">
            <div class="lx_text">类型</div>
            <div class="lx">共享账号</div>
          </div>
        </div>
      </div>
    </div>
    <div class="title_1">
      <div class="title_1_shu"></div>
      <div class="title_1_text">扫码支付</div>
    </div>
    <!-- 支付模块 -->
    <div v-show="login_flag" class="pay_box">
      <div class="pay_box_left">
        <div
          @click="pay_fun(0)"
          class="pay_wx"
          :class="{ pay_0: pay_type == 1, pay_1: pay_type == 0 }"
        ></div>
        <div
          @click="pay_fun(1)"
          class="pay_zfb"
          :class="{ pay_0: pay_type == 0, pay_1: pay_type == 1 }"
        ></div>
      </div>
      <div class="pay_pic">
        <vue-qr
          :text="paymentImg"
          :margin="8"
          :size="159"
          colorDark="#000"
          colorLight="#fff"
        ></vue-qr>
        <div class="ewm_hide" v-show="pay_times == 0 && !pay_success_flag" @click="refres_ewm"></div>
        <div class="ewm_suc" v-show="pay_times == 0 && pay_success_flag" ></div>
        
        <!-- <div v-show="pay_times == 0" class="shadow">
          二维码已过期
          <span class="ss" @click="refres_ewm">刷新</span>
        </div> -->
        <!-- <div class="suc" v-show="pay_success_flag && pay_times == 0">
          支付成功!
        </div> -->
      </div>
      <div class="pay_info">
        <div class="pay_info_1">应付</div>
        <div class="pay_info_2">
          <span class="s1">￥</span>
          <span class="s2">{{ gameinfo.price }}</span>
        </div>
        <div class="pay_info_3">
          <div class="pay_info_3_check"></div>
          <div @click="goto" class="pay_info_3_text">同意游戏共享协议</div>
        </div>
      </div>
    </div>
    <!-- 绑定手机号模块 -->
    <div v-show="!login_flag" class="login_box">
      <div class="title">
        <div class="title_shu"></div>
        <div class="title_text">确认绑定的手机号</div>
      </div>
      <div class="phone_box">
        <div class="logo_pic"></div>
        <el-input
          class="input_box"
          maxLength="11"
          v-model="ruleForm.phone"
          placeholder="请输入手机号"
          oninput="if(isNaN(value)) { value = null }"
        ></el-input>
      </div>
      <div class="code_box">
        <div class="logo_pic"></div>
        <el-input
          class="input_box"
          maxLength="6"
          v-model="ruleForm.code"
          placeholder="验证码"
          oninput="if(isNaN(value)) { value = null }"
        ></el-input>
        <span @click="sendCode" class="send_code">{{ codename }}</span>
      </div>
      <div class="error_box">{{ error_code }}</div>
      <div @click="loginfn" class="login_btn"></div>
    </div>
  </div>
</template>

<script>
import {
  getPhoneCode,
  login,
  xb_create_order,
  xb_order_status,
} from "@/service/api";
import VueQr from "vue-qr";
export default {
  name: "bd_pay_yx",
  components: {
    VueQr,
  },
  props: {
    gameinfo: Object,
    channelNumber:String
  },
  data() {
    return {
      pay_type: 0, //0是微信  1是支付宝
      login_flag: false, //是否绑定了手机号
      ruleForm: {
        phone: "",
        code: "",
      },
      send_code_flag: false,
      codename: "发送验证码",
      error_code: "",
      //定时器相关
      times: 0,
      timer: null,

      paymentImg: "",
      //支付定时器
      pay_times: 1,
      pay_timer: null,
      pay_success_flag: false,
    };
  },
  methods: {
    init_created() {
      if (localStorage.getItem("token")) {
        this.login_flag = true;
        this.init_fun();
      }
    },
    init_fun() {
      this.ruleForm.phone = "";
      this.ruleForm.code = "";
      this.paymentImg = "";
      this.pay_clearInterval();
      this.create_order();
    },
    refres_ewm() {
      this.pay_clearInterval();
      this.create_order();
    },
    async create_order() {
      this.pay_success_flag = false;
      this.paymentImg = "";
      let params = {
        ch_type: 0,
        pay_type: this.pay_type,
        channel_number: this.channelNumber,
        game_amount: this.gameinfo.price,
        game_id: this.gameinfo.id,
      };
      const data = await xb_create_order(params);
      // console.log(data)
      if (data.base.code == 200) {
        let orderSn = data.data.orderSn;
        this.paymentImg = data.data.url.ret;
        this.paySuccess(this.get_order_status, orderSn);
      }
    },
    paySuccess(getStatusFn, order_sn) {
      this.pay_times = 60;
      this.pay_timer = setInterval(() => {
        if (this.pay_times != 0) {
          this.pay_times = this.pay_times - 1;
          getStatusFn(order_sn);
        } else {
          this.pay_clearInterval();
        }
      }, 1000);
    },
    pay_clearInterval() {
      if (this.pay_timer) {
        clearInterval(this.pay_timer);
        this.pay_timer = null;
        this.pay_times = 0;
      }
    },
    //查看充值是否成功
    async get_order_status(order_sn) {
      let p = {
        order_sn: order_sn,
      };
      const data = await xb_order_status(p);
      // console.log(data);
      if (data.base.code === 200 && data.data.status == 1) {
        // this.$store.dispatch("showMessageboxforever", "充值成功");
        this.pay_success_flag = true;
        this.pay_clearInterval();
      }
    },
    pay_fun(val) {
      if (this.pay_type != val) {
        this.pay_type = val;
        // console.log(this.pay_type);
        this.pay_clearInterval();
        this.create_order();
      }
    },
    goto() {
      const routeData = this.$router.resolve({ path: "/GXUserAgreement" });
      window.open(routeData.href, "_blank");
    },
    closefn() {
      this.$store.state.buywindow = false;
    },
    //登录
    async loginfn() {
      if (this.ruleForm.phone != "" && this.ruleForm.code != "") {
        this.error_code = "";
        const data = await login({
          phone: this.ruleForm.phone,
          code: this.ruleForm.code,
          channelNumber: this.channelNumber,
          type: 0,
        });
        if (data.code === 200) {
          let token = data.data.token;
          if (token.substring(0, 3) != "gw:") {
            token = "gw:" + token;
          }
          localStorage.setItem("token", token);
          // console.log(token);
          this.login_flag = true;
          this.ruleForm.phone = "";
          this.ruleForm.code = "";
          this.init_fun();
        } else {
          this.error_code = "验证码错误！";
        }
      } else {
        this.error_code = "*手机号或验证码不能为空";
      }
    },
    //发送验证码
    async sendCode() {
      if (!this.send_code_flag) {
        if (this.ruleForm.phone != "") {
          const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
          if (!reg.test(this.ruleForm.phone)) {
            this.error_code = "*请输入正确的手机号";
          } else {
            const data = await getPhoneCode({
              phone: this.ruleForm.phone,
            });
            this.send_code_flag = true;
            this.error_code = "";
            this.times = 60;
            this.codename = "已发送(" + this.times + ")";
            this.timer = setInterval(() => {
              if (this.times != 0) {
                this.times = this.times - 1;
                this.codename = "已发送(" + this.times + ")";
              } else {
                this.clearInterval();
              }
            }, 1000);
            //console.log(data)
            if (data.code === 200) {
            }
          }
        } else {
          this.error_code = "*手机号不能为空";
        }
      }
    },
    clearInterval() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
        this.codename = "发送验证码";
        this.send_code_flag = false;
      }
    },
  },
  directives: {
    drag(el) {
      let oDiv = el; // 当前元素

      // let self = this // 上下文
      // 禁止选择网页上的文字
      document.onselectstart = function () {
        return false;
      };
      oDiv.onmousedown = function (e) {
        // console.log(e.target.className)
        //如果是顶部的div 再移动
        if (e.target.className == "top") {
          // 鼠标按下，计算当前元素距离可视区的距离
          let disX = e.clientX - oDiv.offsetLeft;
          let disY = e.clientY - oDiv.offsetTop;
          document.onmousemove = function (e) {
            // 通过事件委托，计算移动的距离
            let l = e.clientX - disX;
            let t = e.clientY - disY;
            // 移动当前元素
            oDiv.style.left = l + "px";
            oDiv.style.top = t + "px";
          };
          document.onmouseup = function (e) {
            document.onmousemove = null;
            document.onmouseup = null;
          };
          // return false不加的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
          return false;
        }
      };
    },
  },
};
</script>
<style scoped lang="less">
.buywindow {
  width: 848px;
  height: 627px;
  background-color: #000;
  z-index: 9999;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .top {
    width: 100%;
    height: 60px;
    background-color: #1c1d21;
    display: flex;
    align-items: center;
    .top_title {
      font-size: 14px;
      color: #fff;
      margin-left: 20px;
    }
    .top_close {
      width: 20px;
      height: 20px;
      background: url(~@/assets/images/bd/close.png);
      margin-left: 730px;
      cursor: pointer;
    }
    .top_close:hover {
      background: url(~@/assets/images/bd/close_1.png);
    }
  }
  .item_box {
    width: 807px;
    height: 254px;
    background-color: #1c1d21;
    margin-left: 20px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    .item_pic {
      width: 385px;
      height: 226px;
    }
    .item_info {
      width: 385px;
      height: 226px;
      background-color: #1c1d21;
      .item_info_1 {
        width: 375px;
        display: flex;
        margin-left: 10px;
        justify-content: space-between;
        .item_name {
          font-size: 24px;
          color: #fff;
          font-weight: bold;
        }
        .price_box {
          color: #ffb823;
          font-weight: bold;
          .s1 {
            font-size: 15px;
          }
          .s2 {
            font-size: 24px;
          }
        }
      }
      .item_info_2 {
        width: 375px;
        height: 107px;
        background-color: #000;
        margin-left: 10px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .item_info_2_bb {
        width: 100%;
        // border: 1px solid red;
        display: flex;
        margin-left: 14px;
        .bb_text {
          font-size: 12px;
          color: #fff;
        }
        .bb {
          width: 56px;
          height: 21px;
          background: url(~@/assets/images/bd/bb.png);
          margin-left: 10px;
          font-size: 12px;
          color: #ffe4ab;
          text-align: center;
          line-height: 21px;
        }
      }
      .item_info_2_lx {
        width: 100%;
        // border: 1px solid red;
        display: flex;
        margin-left: 14px;
        .lx_text {
          font-size: 12px;
          color: #fff;
          text-align: center;
          line-height: 21px;
        }
        .lx {
          width: 56px;
          height: 21px;
          border: 1px solid #00ca49;
          border-radius: 5px;
          margin-left: 10px;
          font-size: 12px;
          color: #00ca49;
          text-align: center;
          line-height: 21px;
        }
      }
    }
  }
  .title_1 {
    width: 807px;
    height: 20px;
    background-color: #000;
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    .title_1_shu {
      width: 2px;
      height: 9px;
      background-color: #ffe4ab;
    }
    .title_1_text {
      font-size: 14px;
      color: #fff;
      margin-left: 3px;
    }
  }
  .pay_box {
    width: 804px;
    height: 207px;
    background-color: #343539;
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    .pay_box_left {
      width: 310px;
      height: 100%;
      //  border:1px solid red;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .pay_wx {
        width: 83px;
        height: 30px;
        background-color: #fff;
        background: url(~@/assets/images/bd/wx.png);
        cursor: pointer;
      }

      .pay_zfb {
        width: 83px;
        height: 30px;
        background-color: #fff;
        background: url(~@/assets/images/bd/zfb.png);
        cursor: pointer;
      }
      .pay_0 {
        background-position: 83px 0;
      }
      .pay_1 {
        background-position: 0 0;
      }
    }
    .pay_pic {
      width: 159px;
      height: 159px;
      background-color: #fff;
      .shadow {
        color: #000;
        .ss {
          text-decoration: underline;
          color: red;
          cursor: pointer;
        }
      }
    }
    .ewm_hide{
      position: relative;
      top: -163px;
      width: 159px;
      height: 159px;
      background: url(~@/assets/images/bd/ewm.png);
      cursor: pointer;
    }
    .ewm_suc{
      position: relative;
      top: -164px;
      width: 159px;
      height: 159px;
      background: url(~@/assets/images/bd/ewm_suc.png);
      cursor: pointer;
    }
    .pay_info {
      flex: 1;
      height: 159px;
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 30px;
      .pay_info_1 {
        font-weight: bold;
        font-size: 16px;
        color: #fff;
        text-align: start;
      }
      .pay_info_2 {
        text-align: start;
        color: #ffb823;
        font-weight: bold;
        .s1 {
          font-size: 14px;
        }
        .s2 {
          font-size: 28px;
        }
      }
      .pay_info_3 {
        display: flex;
        .pay_info_3_check {
          width: 18px;
          height: 18px;
          background: url(~@/assets/images/bd/check.png);
        }
        .pay_info_3_text {
          font-size: 12px;
          color: #b0b0b0;
          cursor: pointer;
          margin-left: 5px;
        }
        .pay_info_3_text:hover {
          color: #fff;
        }
      }
    }
  }
  .login_box {
    width: 804px;
    height: 207px;
    background-color: #343539;
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: space-around;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      .title_shu {
        width: 4px;
        height: 13px;
        background-color: #ffb823;
      }
      .title_text {
        margin-left: 5px;
        color: #ffb823;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .phone_box {
      width: 251px;
      height: 40px;
      border: 1px solid #7e7e7e;
      border-radius: 5px;
      display: flex;
      background-color: #1c1d21;
      .logo_pic {
        width: 24px;
        height: 24px;
        margin-left: 6px;
        margin-top: 8px;
        background: url(~@/assets/images/new/login/phone.png);
      }
      .input_box {
        margin-left: 10px;
        margin-top: 5px;
      }
    }
    .phone_box:hover {
      border: 1px solid #5dfe97;
    }
    .code_box {
      width: 251px;
      height: 40px;
      border: 1px solid #7e7e7e;
      border-radius: 5px;
      display: flex;
      background-color: #1c1d21;
      .logo_pic {
        width: 24px;
        height: 24px;
        margin-left: 6px;
        margin-top: 8px;
        background: url(~@/assets/images/new/login/code.png);
      }
      .input_box {
        margin-left: 10px;
        margin-top: 5px;
        width: 70px;
      }
      .send_code {
        font-size: 14px;
        color: #5dfe97;
        cursor: pointer;
        height: 20px;
        margin-top: 10px;
        margin-left: 50px;
      }
      .send_code:hover {
        color: #9dffc1;
      }
    }
    .code_box:hover {
      border: 1px solid #5dfe97;
    }
    /deep/.el-input__inner {
      height: 28px;
      width: 120px;
      background: #1c1d21;
      border: none;
      font-size: 16px;
      // font-family: PingFangSC-Regular, PingFang SC;
      color: #d4d4d4;
    }
    .error_box {
      font-size: 12px;
      color: #ff5c5c;
      text-align: start;
    }
    .login_btn {
      width: 254px;
      height: 40px;
      background: url(~@/assets/images/bd/login_btn.png);
      cursor: pointer;
    }
    .login_btn:hover {
      background-position: 254px 0;
    }
  }
}
</style>
