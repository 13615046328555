<template>
  <div class="box">
 <p>为使用平台提供的游戏共享服务（以下简称为：本服务），请用户阅读并遵守《玩家驿站游戏共享服务协议》（以下简称为：本协议）。请用户务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，如用户在下单时点击接受本协议，即视为用户已阅读并同意本协议的约束。
</p><p>一、服务范围
</p><p>用户根据消费需求在平台提交共享订单并支付对应的价款，平台收到用户支付的消费款项后，根据用户的订单指令，为用户提供相应正版游戏的共享服务。
</p><p>二、服务方式
</p><p>2.1 购买账号后用户拥有该共享账号的使用权，平台为用户提供包含相应正版游戏的共享账号。共享账号仅限于平台提供的客户端中使用，用户通过客户端一键登录，进行游戏。
</p><p>2.2共享账号与独享账号的区别，共享账号为平台为购买共享账号的用户进行账号随机分配，独享账号为用户购买独享游戏账号后，平台为其分配1对1的独享账号，用户可在玩家驿站平台内自行提取账号进行保管。
</p><p>2.3 购买的共享游戏每月不限游戏时长。用户进行共享游戏时，选择的单次游戏时长提交成功后即视为消耗，不支持暂停或取消，在单次游戏时长到期前，用户可延长游戏时长。
</p><p>2.4 使用共享游戏过程中，基于系统情况可能会为用户提供不同的共享账号，游戏的状态/进度/存档等会存在不一致；游戏高峰期也可能存在共享账号无空闲，用户暂时不能进行游戏的情况；共享游戏不支持游戏账号资料提取、游戏回收等功能；购买共享游戏即表示用同意并接受上述情况的存在。
</p><p>三、双方权利与义务
</p><p>3.1 用户应确保其电脑硬件及系统配置达到游戏的要求，并具备稳定的网络环境。
</p><p>3.2 用户应确保在游戏过程中不存在使用外挂、脚本等违规行为，如因用户违规行为导致的共享失效等情况，平台方已提供的共享服务不予退换，由用户承担全部损失。
</p><p>3.3 平台方向用户发送共享游戏后，由于用户本身问题导致账号被封禁等问题，平台方不承担责任，平台方无义务为用户重新提供共享游戏。
</p><p>3.4 平台方向用户发送共享游戏后，其泄露、失效的风险由用户承担。如平台方提供的共享游戏失效或异常的问题，由用户向平台方申请核实，若该共享游戏在平台方交付用户前失效或异常（以平台方交付时间为准），平台方将为用户自动更换、补发共享账号。
</p><p>3.5 若因平台系统自身故障原因造成用户支付失实，在平台恢复、存有有效数据和用户提供合法有效凭证的情况下，平台将根据用户支付情况作出变动措施。
</p><p>3.6 特别说明：本平台开发的客户端程序与Steam官方之间不存在任何关联，特此声明！
</p><p>四、退款说明
</p><p>4.1  用户可于购买共享游戏商品后的任何时间，与平台协商退款；
</p><p>4.2  如用户未出现协议第三条《双方权利与义务》第3.2，3.3，3.4款情况，平台可接受用户退款请求，退款金额计算方式如下：
</p><p>4.2.1 每笔退款订单（以用户实际发生的订单数为准）收取5元的手续费，用于运营及人力折损；
</p><p>4.2.2 如用户购买后未启动过游戏，则可按订单金额减去手续费退款，即退款金额=实付金额-5元手续费
</p><p>4.2.3 如用户购买后未启动过游戏，则需额外扣取折旧费用，费用为订单金额的50%，即退款金额=实付金额*50%-5元手续费；
</p><p>4.3  如您购买相关服务，则视为您接受如上退款规则；
</p><p>其他免责性声明：
</p><p>当平台声明用户权益受到如下不可抗力因素时，相应权益无法维持自动终止。
</p><p>1.因自然原因引起的自然现象，如火灾、旱灾、地震、风灾、大雪、山崩等导致游戏或平台服务异常；
</p><p>2.因政策、法律、战争、动乱、政府干预、罢工、世贸纠纷等变动导致该游戏无法运营；
</p><p>3.因上游游戏开发商、运营商停服、中止运营，强力的风控手段禁止账号共享等；
</p><p>4.因平台严重亏损，无法支持持续性经营等。</p>
 </div>
</template>

<script>

export default {
  components: {
      
   },
  data() {
    return {
         
    };
  },
  created(){
    
     },

  methods:{
   
       
  },
}
</script>
<style scoped lang='less'>
.box{
    position:absolute;
    background-color: #fff;
    width: 100%;
    height: 1500px;
    top: 0px;
    display: flex;
    flex-direction: column;
   p{
       font-size: 20px;
       left: 10px;
       text-align: start;
       margin-left: 50px;
   }
}

</style>
