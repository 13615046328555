<template>
  <div class="box">
        <p>•	欢迎您使用玩家驿站网站的服务！</p>
 <p>•本协议是您与玩家驿站网站（简称“本站”，网址：www.wanjiayizhan.com）所有者（以下简称为“玩家驿站”）之间就玩家驿站网站服务等相关事宜所订立的契约，请您仔细阅读本注册协议，您点击“注册”按钮后，本协议即构成对双方有约束力的法律文件。
</p>
<p>玩家驿站网站指包括但不限于www.wanjiayizhan.com及其他遍及全球的玩家驿站相关网站及网站相关服务。玩家驿站网站的所有权、著作权等完整相关权利归属于四川精诚智达网络技术有限公司。
</p>
<p>为使用玩家驿站的服务，您应当阅读并遵守《玩家驿站网络服务协议》 （以下简称“本协议”）。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款。
</p>
<p>除非您已阅读并接受本协议所有条款，否则您无权使用包括玩家驿站网站在内的玩家驿站提供的服务。您使用玩家驿站的服务即视为您已阅读且清楚理解并同意本协议的约束，否则请勿使用玩家驿站网站及相关服务。
</p>
<p>
未成年人禁止参与本站所有交易与服务。
</p>
<p>
•	一、定义
</p>
<p>
1.1“用户”：指您本人，即玩家驿站相关服务的使用人；
</p>
<p>
1.2“玩家驿站”：指玩家驿站网站；
</p>
<p>
1.3“本服务”：指玩家驿站根据实际情况，向用户提供的包括但不限于货品采购、货品出售的服务。
</p>
<p>
1.4“货品”：指激活码(CDK)、账号等。
</p>
<p>

•	二、帐号安全
</p>
<p>
2.1因您保管不善可能导致遭受盗号或密码失窃，责任由您自行承担。如您使用第三方账号（如Steam平台账号等）登录玩家驿站服务，相关账号安全及保障应由您及第三方进行维护。
</p>
<p>
2.2您不应将自己在玩家驿站网站处使用的帐号、密码转让或出借予他人使用。如您发现自己在玩家驿站网站处使用的帐号遭他人非法使用，应立即通知玩家驿站，并有权通知玩家驿站采取措施暂停该账号的登录和使用。同时，您也应向玩家驿站提供与被停用账号注册信息相一致的个人有效身份信息。玩家驿站核实您所提供的个人有效身份信息与所注册的身份信息相一致的，应当及时采取措施暂停该账号的登录和使用。若您没有提供个人有效身份证件或者提供的个人有效身份信息与所注册的身份信息不一致的，玩家驿站有权拒绝上述请求。此外，因黑客行为或您的保管疏忽导致您在玩家驿站网站处的帐号、密码遭他人非法使用，玩家驿站不承担任何责任。
</p>
<p>
•	三、用户个人信息保护
</p>
<p>
3.1保护您的个人信息是玩家驿站的一项基本原则。
</p>
<p>
3.2您在注册帐号或使用本服务的过程中，可能需要填写一些必要的信息。若国家法律法规有特殊规定的，您需要填写真实的身份信息。若您填写的信息不完整，则无法使用本服务或在使用过程中受到限制。
</p>
<p>
3.3一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。
</p>
<p>
3.4玩家驿站将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。
</p>
<p>
3.5未经您的同意，玩家驿站不会向玩家驿站以外的任何公司、组织和个人披露您的个人信息，但法律法规另有规定的除外。
</p>
<p>
3.6玩家驿站非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用玩家驿站的服务前，应事先取得您家长或法定监护人（以下简称"监护人"）的同意。
</p>
<p>
3.7 用户应自行诚信向本站提供注册资料，用户同意其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且玩家驿站保留终止用户使用玩家驿站各项服务的权利。
</p>
<p>
3.8 用户在本站进行浏览、充值等活动时，涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，本站将予以严格保密，除非得到用户的授权或法律另有规定，本站不会向外界披露用户隐私信息。
</p>
<p>
3.9 用户注册成功后，将产生用户名和密码等账户信息，您可以根据本站规定改变您的密码。用户应谨慎合理的保存、使用其用户名和密码。用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知本站并向公安机关报案。
</p>
<p>
3.10 用户同意，玩家驿站拥有通过邮件、短信电话等形式，向在本站注册、充值、活动等告知信息的权利。
</p>
<p>
3.11 用户不得将在本站注册获得的账户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。
</p>
<p>
3.12 用户同意，玩家驿站有权使用用户的注册信息、用户名、密码等信息，登录进入用户的注册账户，进行证据保全，包括但不限于公证、见证等。
</p>
<p>•	四、使用本服务的方式
</p>
<p>4.1您承诺以真实身份注册成为玩家驿站的用户，并保证所提供的个人身份资料信息真实、完整、有效，依据法律规定和必备条款约定对所提供的信息承担相应的法律责任。您以真实身份注册成为玩家驿站用户后，需要修改所提供的个人身份资料信息的，玩家驿站应当及时、有效地为您提供该项服务。
</p>
<p>4.2玩家驿站有权审查您注册时所提供的身份信息是否真实、有效，并应积极地采取技术与管理等合理措施保障您账号的安全、有效；您有义务妥善保管您的账号及密码，并正确、安全地使用您的账号及密码。任何一方未尽上述义务导致账号密码遗失、账号被盗等情形而给对方及／或第三方造成损害或损失的，应当承担由此产生的法律责任。
</p>
<p>4.3您对登录后所持账号产生的行为依法享有权利和承担责任
</p>
<p>4.4除非您与玩家驿站另有约定，您同意本服务仅为您个人使用。
</p>
<p>4.5您应当通过玩家驿站提供或认可的方式使用本服务。您依本协议条款所取得的权利不可转让。
</p>
<p>4.6您不得使用未经玩家驿站授权的插件、外挂或第三方工具对本协议项下的服务进行干扰、破坏、修改或施加其他影响。
</p>
<p>4.7您通过服务对程序的使用受制于某些已经包含在规则中的行为准则。这些规则将在玩家驿站官方网站公开并不定期更新，所有通过服务使用程序的用户必须遵守。您有义务知晓、了解并遵守这些行为规则。玩家驿站保留权利决定何种行为违反游戏精神，并对该等行为采取其认为合适的相应的处理措施（包括但不限于警告、暂时或永久冻结账号、拒绝提供服务等）。玩家驿站保留随时修改这些行为规则的权利。
</p>
<p>4.8您不可试图通过非法侵入、“破解”密码或任何其他非法方式，未经授权访问玩家驿站网站的任何部分或功能，或者链接至玩家驿站网站或任何玩家驿站服务器的任何其他系统或网络，或者未经授权取得玩家驿站网站提供的任何服务。
</p>
<p>4.9您不可探查、扫描或测试玩家驿站网站或链接至玩家驿站网站之任何网络的弱点，亦不可违反玩家驿站网站或链接至玩家驿站网站之任何网络的安全或认证措施。您不可反向查找、追踪或寻求追踪任何关于玩家驿站网站任何其他用户或访问者或玩家驿站其他客户的信息，包括任何非您所有的玩家驿站账户的来源；或为显示您个人所有信息以外的任何信息之目的，包括但不限于玩家驿站网站提供的身份证明或信息，使用玩家驿站网站或玩家驿站网站提供的任何服务或信息。
</p>
<p>4.10您同意不使用任何设备、软件或程序，干扰或试图干扰玩家驿站网站的正常运行或于玩家驿站网站进行的任何交易，或干扰或试图干扰他人使用玩家驿站网站。
</p>
<p>4.11您不可伪造信头或以其他方式操控识别功能，以伪造您通过玩家驿站网站提交给玩家驿站的任何信息或传输内容的来源，或者伪造玩家驿站网站提供的任何服务的来源。您不可假冒他人、或假冒代表他人，或模仿任何其他个人或实体。
</p>
<p>4.12您不可为任何非法或本使用条款禁止之目的使用玩家驿站网站或任何内容，或者为教唆实施任何非法行为或侵犯玩家驿站或他人权利的其他行为使用玩家驿站网站或任何内容。
</p>
<p>4.13您不可以冒用玩家驿站名号（冒充玩家驿站机器人）或采用其他易误导他人认为您是玩家驿站官方成员的方式试图欺诈或成功欺诈玩家驿站其他用户的财产，否则玩家驿站有权永久冻结您在玩家驿站网站的所有物品和财产，冻结期间无法登录、无法交易、无法提现。如受骗方能提供视频、截图等证据证明被骗物品所有权归其所有，玩家驿站有权根据用户申请退还被骗物品或钱款。
</p>
<p>4.14鉴于网络虚拟交易的特殊性，玩家驿站无法鉴别和判断相关交易各主体之民事权利和行为能力、资质、信用等状况，也无法鉴别和判断虚拟交易或正在交易或已交易之虚拟物品来源、权属、真伪、性能、规格、质量、数量等权利属性、自然属性及其他各种状况。因此，交易各方在交易前应加以仔细辨明，并慎重考虑和评估交易可能产生的各项风险。
</p>
<p>•	五、按现状提供服务
</p>
<p>5.1您理解并同意，玩家驿站的服务是按照现有技术和条件所能达到的现状提供的。玩家驿站会尽最大努力向您提供服务，确保服务的连贯性和安全性；但玩家驿站不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。
</p>
<p>5.2由于网络环境特殊性及网络第三方不确定安全因素，玩家驿站不承诺玩家驿站网站或玩家驿站网站的任何内容、服务或功能无任何错误或不中断，不承诺任何瑕疵将被纠正，或者您对玩家驿站网站的使用将产生特定结果。玩家驿站网站及其内容是基于“现状”且“可获得”而提供。玩家驿站网站提供的所有信息可不经通知而变更。玩家驿站不能确保您从玩家驿站网站下载的任何资料或其他数据无病毒、无污染或不具破坏性。玩家驿站不作任何明示或默示保证，包括任何正确性、非侵权、适销性及适用性的保证。对于任何及所有与您对玩家驿站网站和/或任何玩家驿站服务的使用有关的任何第三方的作为、不作为和行为，玩家驿站概不承担责任。您对玩家驿站网站及任何链接网站的使用，由您承担全部责任。如果对玩家驿站网站或任何内容有任何不满意，您唯一的救济方式是停止使用玩家驿站网站或任何该等内容。救济限制是双方协议的一部分。
</p>
<p>5.3上述免责声明适用于因任何不履行、错误、疏忽、中断、删除、缺陷、操作或传输迟延、电脑病毒、通信线路故障、失窃或破坏或未经授权访问、篡改或使用(无论是违约、侵权、过失或任何其他诉因)而造成的任何损害、责任或伤害。
</p>
<p>•	六、自备设备
</p>
<p>6.1您应当理解，您使用玩家驿站的服务需自行准备与相关服务有关的终端设备（如电脑、调制解调器等装置），并承担所需的费用（如电话费、上网费等费用）。
</p>
<p>6.2您理解并同意，您使用本服务时会耗用您的终端设备和带宽等资源。
</p>
<p>•	七、广告
</p>
<p>7.1您理解并同意，玩家驿站可以在提供服务的过程中自行或由第三方广告商向您发送广告、推广或宣传信息（包括商业与非商业信息），其方式和范围可不经向您特别通知而变更。
</p>
<p>7.2玩家驿站依照法律的规定对广告商履行相关义务，您应当自行判断广告信息的真实性并为自己的判断行为负责，除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，玩家驿站不承担责任。
</p>
<p>•	八、交易及付费服务
</p>
<p>8.1提供的服务包括货品采购与货品销售，根据您的实际需要，您可以选择与玩家驿站签订供货协议及／或销售协议，并相应遵守协议的约定。
</p>
<p>8.2为保证服务质量和正常运营，玩家驿站的部分服务会以收费方式提供，如您使用相关收费服务，视为您知晓并同意相关收费服务内容和标准。
</p>
<p>8.3玩家驿站可能根据实际需要对收费服务的收费标准、方式进行修改和变更，玩家驿站也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，玩家驿站将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。
</p>
<p>•	九、本服务中的软件
</p>
<p>9.1您在使用本服务的过程中可能需要下载软件，对于这些软件，玩家驿站给予您一项个人的、不可转让及非排他性的许可。您仅可为访问或使用本服务的目的而使用这些软件。
</p>
<p>9.2为了改善用户体验、保证服务的安全性及产品功能的一致性，玩家驿站可能会对软件进行更新。您应该将相关软件更新到最新版本，否则玩家驿站并不保证其能正常使用。
</p>
<p>•	十、知识产权声明
</p>
<p>10.1玩家驿站在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归玩家驿站所有，您在使用本服务中所产生的内容的知识产权归您或相关权利人所有。
除另有特别声明外，玩家驿站提供本服务时所依托软件的著作权、专利权及其他知识产权均归玩家驿站所有。
玩家驿站在本服务中所使用的“玩家驿站”、“玩家驿站”及相关LOGO、形象等商业标识，其著作权或商标权归玩家驿站所有。
上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经玩家驿站或相关权利人书面许可，任何人不得以任何形式进行使用或创造相关衍生作品。
</p>
<p>•	十一、用户违法行为
</p>
<p>11.1您在使用本服务时须遵守法律法规，不得利用本服务从事违法违规行为，包括但不限于：
</p>
<p>(1)发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；
</p>
<p>(2)发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；
</p>
<p>(3)恶意虚构事实、隐瞒真相以误导、欺骗他人；
</p>
<p>(4)发布、传送、传播广告信息及垃圾信息；
</p>
<p>(5)其他法律法规禁止的行为。
</p>
<p>11.2 、行为：使用违反命名规则之角色名称：包括但不限于含有人身攻击、淫秽、辱骂、反动及其他危害本游戏形象、同游戏服务专员服务角色名故意相似扰乱正常服务秩序以及国家利益和社会公德性质的文字。
处罚措施：一经发现，立刻删除该角色，请您谨慎选择自己的角色名！
</p>
<p>11.3 、行为：利用各种方式攻击或入侵游戏服务器，或使用异常的方法登录游戏。
处罚措施：直接删除该用户账号，同时保留对此行为之一切法律追诉权。
</p>
<p>11.4 、行为：恶性破坏我们的服务设施，包括但不限于：利用编译或反编译程序修改游戏资料、使用异常的方法登录游戏、使用外挂软件或机器人程序、扰乱正常服务秩序的行为。
处罚措施：违反者将被取消继续进行游戏的权利，予以临时（封停账号的时间包括1天、3天、7天至30天）或永久封停角色或账号，同时保留对此行为之一切法律追诉权。
</p>
<p>11.5 、行为：利用系统的 BUG 、漏洞为自己及他人牟利。
处罚措施：一经发现或经用户举报查证属实后，根据情节轻重，处以警告、临时或永久封停账号或封停角色的处罚。
</p>
<p>11.6 、行为：以同时登录多个游戏账号等非正常方式获取游戏中的虚拟物品（包括但不限于虚拟货币）。
处罚措施：一经发现或经用户举报，删除虚拟物品、临时（封停账号的时间包括1天、3天、7天至30天）或永久立即封停账号。
</p>
<p>11.7 、行为：利用游戏提供的功能进行非法实物交易等违反国家法律法规的行为。
处罚措施：上述行为一经查实，立即注销账号，并交由国家有关机构处理。
如果您违反了本条约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求玩家驿站给予协助。造成损害的，您应依法予以赔偿，玩家驿站不承担任何责任。
如果玩家驿站发现或收到他人举报您发布的信息违反本条约定，玩家驿站有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，玩家驿站有权视您的行为性质，采取包括但不限于暂停或终止服务，限制、冻结，追究法律责任等措施。
您违反本条约定，导致任何第三方损害的，您应当独立承担责任；玩家驿站因此遭受损失的，您也应当一并赔偿。
</p>
<p>•	十二、遵守用户所在地法律监管
</p>
<p>12.1您在使用本服务过程中应当遵守您所在地相关的法律法规，并尊重您所在地的道德和风俗习惯。如果您的行为违反了您所在地的法律法规或道德风俗，您应当为此独立承担责任。
</p>
<p>12.2您应避免因使用本服务而使玩家驿站卷入政治和公共事件，否则玩家驿站有权暂停或终止对您的服务。
</p>
<p>•	十三、用户发送、传播的内容与第三方投诉处理
</p>
<p>13.1您通过本服务发送或传播的内容（包括但不限于网页、文字、图片、音频、视频、图表等）均由您自行承担责任。
</p>
<p>13.2您发送或传播的内容应有合法来源，相关内容为您所有或您已获得权利人的授权。
</p>
<p>13.3您同意玩家驿站可为履行本协议或提供本服务的目的而使用您发送或传播的内容。
</p>
<p>13.4如果玩家驿站收到权利人通知，主张您发送或传播的内容侵犯其相关权利的，您同意玩家驿站有权进行独立判断并采取删除、屏蔽或断开链接等措施。
</p>
<p>•	十四、不可抗力及其他免责事由
</p>
<p>14.1您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，玩家驿站将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失玩家驿站在法律允许的范围内免责。
在法律允许的范围内，玩家驿站对以下情形导致的服务中断或受阻不承担责任：
</p>
<p>（1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；
</p>
<p>（2）您或玩家驿站的电脑软件、系统、硬件和通信线路出现故障；
</p>
<p>（3）您操作不当；
</p>
<p>（4）您通过非玩家驿站授权的方式使用本服务；
</p>
<p>14.2您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，玩家驿站不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：
</p>
<p>（1）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；
</p>
<p>（2）因使用本协议项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；
</p>
<p>（3）其他因网络信息或用户行为引起的风险。
</p>
<p></p>
<p>14.3您理解并同意，本服务并非为某些特定目的而设计，包括但不限于核设施、军事用途、医疗设施、交通通讯等重要领域。如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，玩家驿站不承担法律责任。
</p>
<p>14.4 您理解并同意，玩家驿站交易平台提供的服务有赖于Steam等第三方平台支持。如因Steam等第三方政策变动或服务变更，导致玩家驿站平台服务停滞、变化或用户数据、财产损失等情况，玩家驿站不承担相应责任。
</p>
<p>14.5玩家驿站依据本协议约定获得处理违法违规内容的权利，该权利不构成玩家驿站的义务或承诺，玩家驿站不能保证及时发现违法行为或进行相应处理。
</p>
<p>14.6在任何情况下，您不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意玩家驿站有关防范诈骗犯罪的提示。
</p>
<p>•	十五、协议的生效与变更
</p>
<p></p>
<p>15.1 您使用玩家驿站的服务即视为您已阅读本协议并接受本协议的约束。
玩家驿站有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款。
</p>
<p>15.2 本协议条款变更后，如果您继续使用玩家驿站提供的软件或服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用玩家驿站提供的软件或服务。
</p>
<p>15.3 本协议签订地为中华人民共和国四川省成都市高新技术产业开发区人民法院。
</p>
<p>15.4 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
</p>
<p>15.5 若您和玩家驿站之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交中国国际经济贸易仲裁委员会四川分会根据现行有效的仲裁规则进行仲裁。
</p>
<p>15.6 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
</p>
<p>15.7 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
</p>
<p>•	十六、服务的变更、中断或终止
</p>
<p>16.1 鉴于网络服务的特殊性，您同意玩家驿站有权随时变更、中断或终止部分或全部的网络服务。
</p>
<p>16.2 您理解并同意，玩家驿站需要定期或不定期地对提供网络服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护，如因此类情况而造成网络服务在合理时间内的中断，玩家驿站无需为此承担任何责任，但玩家驿站将尽可能事先进行通告。
</p>
<p>16.3 玩家驿站服务的各个新版本，玩家驿站可以自行决定是否保留为玩家驿站服务增加附加特点和功能，或者提供程序编程之修补、更新和升级的权利。玩家驿站无需向您发出通知即可自行决定修改、停止或暂停您对任何版本玩家驿站服务的使用，和/或禁用您已经使用或安装的玩家驿站服务，以便对相关技术进行检修、改良和/或升级，或者出于其他合理原因；对于由发行和/或不发行玩家驿站服务的新版本而导致的直接或间接损害，将不承担任何赔偿责任。
</p>
<p>16.4 如发生下列任何一种情形，玩家驿站有权不经通知而中断或终止向您提供的服务：
</p>
<p>（1）根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
</p>
<p>（2）您违反相关法律法规或本协议的约定；
</p>
<p>（3）按照法律规定或主管部门的要求；
</p>
<p>（4）出于安全的原因或其他必要的情形。
您有责任自行备份存储在本服务中的数据。如果您的服务被终止，玩家驿站可以从服务器上永久地删除您的数据。服务终止后，玩家驿站没有义务向您返还数据。
</p>
<p>•	十七、禁止行为
</p>
<p>17.1禁止通过第三方电子商务交易平台或线下交易等方式对游戏账号、游戏道具、游戏装备、游戏币、游戏激活码等进行贩卖和/或购买。若因个人原因进行了交易,您将承受交易过程中造成的一切损失!
</p>
<p>17.2禁止未成年人购买消费本网站所有的服务。
•	十八、其他
</p>
<p>19.1 玩家驿站网站所有者是指在政府部门依法许可或备案的玩家驿站网站经营主体。
</p>
<p>19.2 玩家驿站尊重用户和消费者的合法权利，本协议及本网站上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。本站欢迎用户和社会各界提出意见和建议，玩家驿站将虚心接受并适时修改本协议及本站上的各类规则。
</p>
<p>19.3 您点登录界面中的按钮,即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。
19.4如果您对本协议或本服务有意见或建议，可与玩家驿站客户服务部门联系（wanjiayizhan@foxmail.com），我们会给予您必要的帮助。</p>
  </div>
</template>

<script>

export default {
  components: {
      
   },
  data() {
    return {
         
    };
  },
  created(){
    
     },

  methods:{
   
       
  },
}
</script>
<style scoped lang='less'>
.box{
    position:absolute;
    background-color: #fff;
    width: 100%;
    height: 1500px;
    top: 0px;
    display: flex;
    flex-direction: column;
   p{
       font-size: 20px;
       left: 10px;
       text-align: start;
       margin-left: 50px;
   }
}

</style>
