<template>
  <div class="box">
    <BdpAYyX
      ref="windowyx"
      :gameinfo="gameinfo"
      :channelNumber="channelNumber"
    ></BdpAYyX>
    <BdpAYhY ref="windowhy" :channelNumber="channelNumber"></BdpAYhY>
    <div class="top">
      <div class="downloadbtn" @click="downloadFile"></div>
      <div class="price">{{ gameinfo.price }}</div>
      <div class="buy" @click="buyfn" :class="{buy_on:!mouse_in}"></div>
      <div class="mfbtn" @click="downloadFile" @mouseenter="mouse_in=true" @mouseleave="mouse_in=false"></div>
      <div class="hybtn" @click="buyHYfn" @mouseenter="mouse_in=true" @mouseleave="mouse_in=false"></div>
    </div>
    <div class="mid"></div>
  </div>
</template>

<script>
import BdpAYyX from "@/components/bd_pay_yx_p";
import BdpAYhY from "@/components/bd_pay_hy_p";
import { xb_getProInfo, addDown } from "@/service/api";
export default {
  components: {
    BdpAYyX,
    BdpAYhY,
  },
  data() {
    return {
      mouse_in:false,
      gameinfo: {
        name: "暗黑破坏神4",
        banben: "标准版",
        price: 999.99,
        id: 999,
        pic: "diablo_p.png",
      },
      channelNumber: '107',
    };
  },
  created() {},
  mounted() {
    this.getproinfo();
  },
  methods: {
    gotoMain() {
      const routeData = this.$router.resolve({ path: "/" });
      window.open(routeData.href, "_blank");
    },
    downFile(file) {
      let obj = {};
      obj = {
        url: file.url,
        name: file.filename,
      };
      return obj;
    },
    downloadFile() {
      const fileUrl = this.$store.state.downfile_diablo_bd.url;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute(
        "download",
        "this.$store.state.downfile_diablo_bd.filename"
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.addDownfun();
    },
    async addDownfun() {
      let p = {
        type: 2,
        gameName: "百度暗黑破坏神",
      };
      const res = await addDown(p);

      if (res.code == 200) {
      } else {
      }
    },
    async getproinfo() {
      const data = await xb_getProInfo({
        product_id: 4346,
        origin: "ybjsqapi01",
      });
      if (data.status_code == 1000) {
        //成功
        this.gameinfo.name = data.data.product_name;
        this.gameinfo.id = data.data.product_id;
        this.gameinfo.price = data.data.standard_price.group.price;
      }
    },

    buyfn() {
      if (!this.$store.state.buywindowHY) {
        this.$store.state.buywindow = true;
        this.$refs.windowyx.init_created();
      }
    },
    buyHYfn() {
      if (!this.$store.state.buywindow) {
        this.$store.state.buywindowHY = true;
        this.$refs.windowhy.init_created();
      }
    },
  },
};
</script>
<style scoped lang="less">
.box {
  width: 1920px;
  height: 4064px;
  display: flex;
  flex-direction: column;
  .top {
    width: 1920px;
    height: 1080px;
    background: url(~@/assets/images/bd/s1_p/top.png);
    display: flex;
    flex-direction: column;
    .downloadbtn {
      width: 238px;
      height: 68px;
      //    border: 1px solid red;
      margin-left: auto;
      margin-top: 28px;
      margin-right: 48px;
      cursor: pointer;
      background: url(~@/assets/images/bd/s1_p/downloadbtn.png);
      background-position: -10px 0;
    }
    .downloadbtn:hover {
      background-position: 249px 0;
    }
    .price {
      color: #ffb823;
      font-weight: bold;
      font-size: 50px;
      margin-left: auto;
      margin-top: 340px;
      margin-right: 450px;
    }
    .buy {
      width: 357px;
      height: 84px;
      //    border: 1px solid red;
      margin-left: auto;
      margin-top: 20px;
      margin-right: 236px;
      cursor: pointer;
      background: url(~@/assets/images/bd/s1_p/buy.png);
      
    }
    .buy_on{
      background-position: 357px 0;
    }
    //    .buy:hover{
    //        background-position:  357px 0;
    //    }

    .mfbtn {
      width: 357px;
      height: 84px;
      //    border: 1px solid red;
      margin-left: auto;
      margin-top: 8px;
      margin-right: 236px;
      cursor: pointer;
      background: url(~@/assets/images/bd/s1_p/mfbtn.png);
    }
    .mfbtn:hover {
      background-position: 357px 0;
    }
    .hybtn {
      width: 357px;
      height: 84px;
      //    border: 1px solid red;
      margin-left: auto;
      margin-top: 8px;
      margin-right: 236px;
      cursor: pointer;
      background: url(~@/assets/images/bd/s1_p/hybtn.png);
    }
    .hybtn:hover {
      background-position: 357px 0;
    }
  }
  .mid {
    width: 1920px;
    flex: 1;
    background: url(~@/assets/images/bd/s1_p/mid.png);
  }
}
</style>
