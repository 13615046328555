export default {
    bind(el, binding) {
        el.addEventListener('click', () => {
            const a = document.createElement('a')
            const url = binding.value.url // 完整的url则直接使用
            fetch(url, { mode: 'no-cors' }).then(res => {
                a.href = url
                a.download = binding.value.filename || '' // 下载文件的名字
                document.body.appendChild(a)
                a.click()
                //在资源下载完成后 清除 占用的缓存资源
                window.URL.revokeObjectURL(a.href);
                document.body.removeChild(a);
            })
        })
    }
}
