<template>
  <div id="app">
    <Top v-show="$store.state.com_show.Top"></Top>
    <FooterNav v-show="$store.state.com_show.FooterNav"></FooterNav>
    <Login v-show="$store.state.com_show.Login"></Login>
    <Cusservice v-show="$store.state.com_show.Cusservice"></Cusservice>
    <DialigGywm ></DialigGywm>
    <router-view/> 
  </div>
</template>
<script>
import Top from "@/components/top";
import FooterNav from "@/components/footernav";
import Login from "@/components/login";
import Cusservice from "@/components/cusservice";
import DialigGywm from "@/components/dialig_gywm";


export default {
   components: {
    Top,
    FooterNav,
    Login,
    Cusservice,
    DialigGywm,
   },
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  background-color: #1a1a1a;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
