<template>
  <div class="buywindow" v-show="$store.state.buywindow_tg">
    <div class="top">
      <div class="game_name">{{ game_bb_info.name }}</div>
      <div class="closebtn" @click="closefn()"></div>
    </div>
    <div class="main_box">
      <div class="left_box"></div>
      <div class="right_box">
        <div class="title_1">游戏版本</div>
        <div class="yx_banben_box">
          <div
            class="bb"
            :class="{ bb_on: game_bb == 0 }"
            @click="switch_bb(0)"
          >
            普通版
          </div>
          <div
            class="bb"
            v-show="gameinfo.id == 3997 || gameinfo.id == 4380"
            style="margin-left: 24px"
            :class="{ bb_on: game_bb == 1 }"
            @click="switch_bb(1)"
          >
            豪华版
          </div>
          <div
            class="bb"
            v-show="gameinfo.id == 3997"
            style="margin-left: 24px"
            :class="{ bb_on: game_bb == 2 }"
            @click="switch_bb(2)"
          >
            高级版
          </div>
        </div>
        <div class="title_2">游戏类型</div>
        <div class="tx_type">共享</div>
        <div class="title_3">游戏赠品</div>
        <div class="yxzp"></div>
        <div class="title_4">绑定手机号</div>
        <div class="suc_text" v-show="login_flag">
          手机号{{ ruleForm.phone }}绑定成功!
        </div>
        <div class="phone_box" v-show="!login_flag">
          <div class="phone_title">手机号:</div>
          <div class="phone">
            <el-input
              class="input_box"
              maxLength="11"
              v-model="ruleForm.phone"
              placeholder="请输入手机号"
              oninput="if(isNaN(value)) { value = null }"
            ></el-input>
          </div>
          <div class="yzm_title">验证码:</div>
          <div class="yzm_code">
            <div class="code_box">
              <el-input
                class="input_box"
                maxLength="6"
                v-model="ruleForm.code"
                placeholder="请输入验证码"
                oninput="if(isNaN(value)) { value = null }"
              ></el-input>
            </div>
            <div class="send_code_text" @click="sendCode()">{{ codename }}</div>
          </div>
          <div class="phone_confirm" @click="loginfn()">确认</div>
        </div>
        <div class="title_5">支付方式</div>
        <div class="pay_box">
          <div class="pay_type_box">
            <div
              class="zfb_type"
              @click="pay_fun(1)"
              :class="{ type_on: pay_type == 1 }"
            >
              <div class="t_1">
                <div class="zfb_ico"></div>
                <div class="zfb_text">支付宝</div>
              </div>
              <div v-show="pay_type == 1" class="down_line"></div>
            </div>
            <div
              class="wx_type"
              @click="pay_fun(0)"
              :class="{ type_on: pay_type == 0 }"
            >
              <div class="t_2">
                <div class="wx_ico"></div>
                <div class="wx_text">微信</div>
              </div>
              <div v-show="pay_type == 0" class="down_line"></div>
            </div>
          </div>
          <div class="ewm_box">
            <vue-qr
              :text="paymentImg"
              :margin="8"
              :size="100"
              colorDark="#000"
              colorLight="#fff"
            ></vue-qr>
            <div class="login_suc_pic" v-show="!login_flag" @click="show_e_c()">
              <div class="l_p"></div>
            </div>
            <div class="error_code" v-show="error_code_show">
              {{ error_code }}
            </div>
            <div
              class="ewm_hide"
              v-show="pay_times <= 1 && !pay_success_flag"
              @click="refres_ewm"
            ></div>
            <div class="ewm_suc" v-show="pay_success_flag"></div>
          </div>
          <div class="pay_info">
            <div class="pay_info_top">
              <div class="pay_info_top_1">应付金额:</div>
              <div class="pay_info_top_2">￥{{ game_bb_info.price }}</div>
              <div class="pay_info_top_3">
                原价￥{{ gameinfo.original_price }}
              </div>
            </div>
            <div class="pay_info_m">
              已为您节约了￥{{ gameinfo.original_price - game_bb_info.price }}
            </div>
            <div class="pay_info_b">
              <div class="pay_info_b_1">购买即代表阅读并同意</div>
              <div class="pay_info_b_2" @click="goto()">《游戏服务协议》</div>
            </div>
          </div>
          <div class="quanyi_box"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import {
  xb_getProInfo,
  getPhoneCode,
  login,
  xb_create_order,
  xb_order_status,
} from "@/service/api";
export default {
  name: "bd_tg_yx",
  components: {
    VueQr,
  },
  props: {
    gameinfo: Object,
    channelNumber: String,
  },
  data() {
    return {
      game_bb: 0,
      game_bb_info: {
        id: 0,
        name: "",
        price: 0,
      },
      paymentImg: "",
      pay_type: 1, //0是微信  1是支付宝
      ruleForm: {
        phone: "",
        code: "",
      },
      //是否绑定了手机号
      login_flag: false,
      error_code_show: false,
      //发送验证码
      send_code_flag: false,
      codename: "发送验证码",
      error_code: "*请先绑定手机号！",
      //验证码定时器相关
      times: 0,
      timer: null,
      //支付定时器
      pay_times: 0,
      pay_timer: null,
      pay_success_flag: false,
    };
  },
  methods: {
    init_created() {
      console.log("init_created");
      this.getproinfo();
      this.init_fun();
    },
    closefn(){
      this.$store.state.buywindow_tg = false
      //重置支付信息
      this.paymentImg = "";
      this.pay_type = 1;
      this.error_code_show = false;
      this.pay_clearInterval();
    },
    init_fun() {
      if (localStorage.getItem("token") && localStorage.getItem("t_phone")) {
        this.login_flag = true;
        this.ruleForm.phone=localStorage.getItem("t_phone")
        this.game_bb = 0;
        this.paymentImg = "";
        this.pay_type = 1;
        this.times = 0;
        this.timer = null;
        this.pay_clearInterval();
        this.create_order();
      } else {
        this.game_bb = 0;
        this.paymentImg = "";
        this.pay_type = 1;
        this.ruleForm.phone = "";
        this.ruleForm.code = "";
        //是否绑定了手机号
        this.login_flag = false;
        this.error_code_show = false;
        //发送验证码
        this.send_code_flag = false;
        this.codename = "发送验证码";
        this.error_code = "*请先绑定手机号！";
        //验证码定时器相关
        this.times = 0;
        this.timer = null;
        this.pay_clearInterval();
        this.create_order();
      }
    },
    refres_ewm() {
      this.pay_clearInterval();
      this.create_order();
    },
    async create_order() {
      if (this.login_flag) {
        this.pay_success_flag = false;
        this.paymentImg = "";
        let params = {
          ch_type: 0,
          pay_type: this.pay_type,
          channel_number: this.channelNumber,
          game_amount: this.game_bb_info.price,
          game_id: this.game_bb_info.id,
        };
        const data = await xb_create_order(params);
        // console.log(data)
        if (data.base.code == 200) {
          let orderSn = data.data.orderSn;
          this.paymentImg = data.data.url.ret;
          this.paySuccess(this.get_order_status, orderSn);
        }
      }
    },
    //查看充值是否成功
    async get_order_status(order_sn) {
      let p = {
        order_sn: order_sn,
      };
      const data = await xb_order_status(p);
      // console.log(data);
      if (data.base.code === 200 && data.data.status == 1) {
        // this.$store.dispatch("showMessageboxforever", "充值成功");
        this.pay_success_flag = true;
        this.pay_clearInterval();
      }
    },
    paySuccess(getStatusFn, order_sn) {
      this.pay_times = 60;
      this.pay_timer = setInterval(() => {
        if (this.pay_times != 0) {
          this.pay_times = this.pay_times - 1;
          getStatusFn(order_sn);
        } else {
          this.pay_clearInterval();
        }
      }, 1000);
    },
    pay_clearInterval() {
      if (this.pay_timer) {
        clearInterval(this.pay_timer);
        this.pay_timer = null;
        this.pay_times = 0;
      }
    },
    switch_bb(val) {
      this.game_bb = val;
      this.getproinfo();
    },
    async getproinfo() {
      const data = await xb_getProInfo({
        product_id: this.gameinfo.extra_id[this.game_bb],
        origin: "ybjsqapi01",
      });
      // console.log(data)
      if (data.status_code == 1000) {
        //成功
        this.game_bb_info.name = data.data.product_name;
        this.game_bb_info.id = data.data.product_id;
        this.game_bb_info.price = data.data.standard_price.group.price;
        console.log(this.game_bb_info);
        //更新二维码
        this.pay_clearInterval();
        this.create_order();
      }
      // console.log(data)
    },
    //提示先绑定手机号
    show_e_c() {
      this.error_code_show = true;
      this.error_code = "*请先绑定手机号！";
      setTimeout(() => {
        this.error_code_show = false;
      }, 5000); // 定时时间
    },
    //发送验证码
    async sendCode() {
      this.error_code_show = false;
      if (!this.send_code_flag) {
        if (this.ruleForm.phone != "") {
          const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
          if (!reg.test(this.ruleForm.phone)) {
            this.error_code = "*请输入正确的手机号";
            this.error_code_show = true;
          } else {
            const data = await getPhoneCode({
              phone: this.ruleForm.phone,
            });
            this.send_code_flag = true;
            this.error_code = "";
            this.times = 60;
            this.codename = "已发送(" + this.times + ")";
            this.timer = setInterval(() => {
              if (this.times != 0) {
                this.times = this.times - 1;
                this.codename = "已发送(" + this.times + ")";
              } else {
                this.clearInterval();
              }
            }, 1000);
            //console.log(data)
            if (data.code === 200) {
            }
          }
        } else {
          this.error_code = "*手机号不能为空";
          this.error_code_show = true;
        }
      }
    },
    clearInterval() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
        this.codename = "发送验证码";
        this.send_code_flag = false;
      }
    },
    //登录
    async loginfn() {
      if (this.ruleForm.phone != "" && this.ruleForm.code != "") {
        this.error_code = "";
        this.error_code_show = false;
        const data = await login({
          phone: this.ruleForm.phone,
          code: this.ruleForm.code,
          channelNumber: this.channelNumber,
          type: 0,
        });
        if (data.code === 200) {
          let token = data.data.token;
          if (token.substring(0, 3) != "gw:") {
            token = "gw:" + token;
          }
          localStorage.setItem("token", token);
          localStorage.setItem("t_phone", this.ruleForm.phone);

          // console.log(token);
          this.login_flag = true;

          this.error_code_show = false;
          this.pay_clearInterval();
          this.create_order();
        } else {
          this.error_code = "验证码错误！";
          this.error_code_show = true;
        }
      } else {
        this.error_code = "*手机号或验证码不能为空";
        this.error_code_show = true;
      }
    },
    pay_fun(val) {
      if (this.pay_type != val) {
        this.pay_type = val;
        // console.log(this.pay_type);
        this.pay_clearInterval();
        this.create_order();
      }
    },
    goto() {
      const routeData = this.$router.resolve({ path: "/GXUserAgreement" });
      window.open(routeData.href, "_blank");
    },
  },
  directives: {},
};
</script>
<style scoped lang="less">
.buywindow {
  width: 870px;
  height: 804px;
  background-color: #000;
  z-index: 9999;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  .top {
    width: 100%;
    height: 58px;
    // border: 1px solid red;
    background: #222;
    display: flex;
    align-items: center;
    .game_name {
      font-size: 24px;
      color: #fff;
      margin-left: 24px;
    }
    .closebtn {
      width: 24px;
      height: 24px;
      background: url(~@/assets/images/bd_tg/closebtn.png);
      margin-left: auto;
      margin-right: 24px;
      cursor: pointer;
    }
    .closebtn:hover {
      opacity: 0.8;
    }
  }
  .main_box {
    display: flex;
    flex: 1;
    //   border: 1px solid red;
    .left_box {
      margin-left: 26px;
      margin-top: 18px;
      width: 24px;
      height: 682px;
      background: url(~@/assets/images/bd_tg/left_index.png);
    }
    .right_box {
      margin-left: 16px;
      margin-top: 18px;
      //   border: 1px solid red;
      width: 778px;
      height: 682px;
      display: flex;
      flex-direction: column;
      .title_1 {
        font-size: 18px;
        color: #e0e0e0;
        text-align: start;
      }
      .yx_banben_box {
        margin-top: 16px;
        display: flex;
        width: 778px;
        height: 37px;
        .bb {
          width: 102px;
          height: 37px;
          background: #222;
          border-radius: 5px;
          font-size: 18px;
          color: #fff;
          line-height: 37px;
          cursor: pointer;
        }
        .bb_on {
          background: #666;
        }
        .bb:hover {
          background: #666;
        }
      }
      .title_2 {
        font-size: 18px;
        color: #e0e0e0;
        text-align: start;
        margin-top: 16px;
      }
      .tx_type {
        margin-top: 16px;
        width: 102px;
        height: 37px;
        background: #222;
        border-radius: 5px;
        font-size: 18px;
        color: #fff;
        line-height: 37px;
        cursor: pointer;
      }
      .title_3 {
        font-size: 18px;
        color: #e0e0e0;
        text-align: start;
        margin-top: 16px;
      }
      .yxzp {
        margin-top: 16px;
        width: 778px;
        height: 180px;
        background: url(~@/assets/images/bd_tg/yxzp.png);
      }
      .title_4 {
        font-size: 18px;
        color: #e0e0e0;
        text-align: start;
        margin-top: 16px;
      }
      .suc_text {
        margin-top: 16px;
        color: greenyellow;
        font-size: 24px;
      }
      .phone_box {
        margin-top: 16px;
        width: 778px;
        height: 44px;
        // border: 1px solid red;
        display: flex;
        align-items: center;
        .phone_title {
          font-size: 16px;
          color: #ccc;
          line-height: 44px;
        }
        .phone {
          margin-left: 12px;
          width: 244px;
          height: 34px;
          border-radius: 5px;
          //   border: 1px solid red;
          background: #1c1d21;
          .input_box {
            margin-top: 5px;
          }
          /deep/.el-input__inner {
            height: 25px;
            width: 200px;
            background: #1c1d21;
            border: none;
            font-size: 16px;
            // font-family: PingFangSC-Regular, PingFang SC;
            color: #d4d4d4;
          }
        }

        .yzm_title {
          font-size: 16px;
          color: #ccc;
          line-height: 44px;
          margin-left: 16px;
        }
        .yzm_code {
          margin-left: 12px;
          width: 244px;
          height: 34px;
          border-radius: 5px;
          // border: 1px solid red;
          background: #1c1d21;
          display: flex;
          .code_box {
            width: 144px;
            height: 34px;
            // border: 1px solid red;
            .input_box {
              margin-top: 5px;
            }
            /deep/.el-input__inner {
              height: 25px;
              width: 100px;
              background: #1c1d21;
              border: none;
              font-size: 16px;
              // font-family: PingFangSC-Regular, PingFang SC;
              color: #d4d4d4;
            }
          }
          .send_code_text {
            width: 100px;
            height: 34px;
            font-size: 16px;
            color: #ffb823;
            line-height: 34px;
            cursor: pointer;
          }
          .send_code_text:hover {
            color: #fff;
          }
          .input_box {
            margin-top: 5px;
          }
        }
        .phone_confirm {
          width: 52px;
          height: 28px;
          background: #3b3b3b;
          border-radius: 5px;
          color: #fff;
          line-height: 28px;
          font-size: 14px;
          margin-left: 16px;
          cursor: pointer;
        }
        .phone_confirm:hover {
          background: #666;
        }
      }
      .title_5 {
        font-size: 18px;
        color: #e0e0e0;
        text-align: start;
        margin-top: 16px;
      }
      .pay_box {
        margin-top: 16px;
        width: 778px;
        height: 120px;
        // border: 1px solid red;
        border-radius: 5px;
        display: flex;
        background: #333;
        .pay_type_box {
          width: 160px;
          height: 100%;
          display: flex;
          flex-direction: column;
          .type_on {
            background: #3f4043;
          }
          .zfb_type {
            width: 100%;
            height: 60px;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            .t_1 {
              width: 100%;
              height: 58px;
              display: flex;
              align-items: center;
              .zfb_ico {
                margin-left: 54px;
                width: 12px;
                height: 12px;
                background: url(~@/assets/images/bd_tg/zfb.png);
                background-size: cover;
              }
              .zfb_text {
                margin-left: 6px;
                font-size: 12px;
                color: #fff;
              }
            }
            .down_line {
              width: 100%;
              height: 2px;
              background-color: #feecb8;
            }
          }
          .wx_type {
            width: 100%;
            height: 60px;
            display: flex;
            flex-direction: column;
            cursor: pointer;
            .t_2 {
              width: 100%;
              height: 58px;
              display: flex;
              align-items: center;
              .wx_ico {
                margin-left: 54px;
                width: 12px;
                height: 12px;
                background: url(~@/assets/images/bd_tg/wx.png);
                background-size: cover;
              }
              .wx_text {
                margin-left: 6px;
                font-size: 12px;
                color: #fff;
              }
            }
            .down_line {
              width: 100%;
              height: 2px;
              background-color: #feecb8;
            }
          }
        }
        .ewm_box {
          width: 102px;
          height: 102px;
          // border: 1px solid red;
          margin-left: 40px;
          margin-top: 9px;
        }
        .pay_info {
          margin-left: 16px;
          width: 262px;
          height: 100%;
          // border: 1px solid red;
          display: flex;
          flex-direction: column;
          .pay_info_top {
            margin-top: 30px;
            width: 100%;
            display: flex;
            .pay_info_top_1 {
              font-size: 14px;
              color: #dedede;
            }
            .pay_info_top_2 {
              margin-left: 17px;
              font-size: 14px;
              color: #ffb823;
            }
            .pay_info_top_3 {
              font-size: 14px;
              color: #dedede;
              margin-left: auto;
              margin-right: 16px;
              text-decoration: line-through;
            }
          }
          .pay_info_m {
            font-size: 14px;
            color: #dedede;
            margin-top: 10px;
            text-align: start;
          }
          .pay_info_b {
            margin-top: 10px;
            display: flex;
            .pay_info_b_1 {
              font-size: 12px;
              color: #dedede;
            }
            .pay_info_b_2 {
              font-size: 12px;
              color: #29aefc;
              cursor: pointer;
            }
          }
        }
        .quanyi_box {
          width: 188px;
          height: 120px;
          margin-left: auto;
          background: url(~@/assets/images/bd_tg/quanyi.png);
          background-size: cover;
        }
      }
    }
  }
}
.login_suc_pic {
  position: relative;
  top: -105px;
  width: 102px;
  height: 102px;
  background: #000;
  opacity: 0.8;
  display: flex;
  align-items: center;
  cursor: pointer;
  .l_p {
    margin: 0 auto;
    width: 44px;
    height: 44px;
    background: url(~@/assets/images/bd_tg/login_suc.png);
    background-size: cover;
  }
}
.error_code {
  position: relative;
  top: -352px;
  left: -110px;
  width: 200px;
  font-size: 16px;
  color: red;
}
.ewm_hide {
  position: relative;
  top: -105px;
  width: 102px;
  height: 102px;
  background: url(~@/assets/images/bd_tg/ewm.png);
  cursor: pointer;
  background-size: cover;
}
.ewm_suc {
  position: relative;
  top: -105px;
  width: 102px;
  height: 102px;
  background: url(~@/assets/images/bd_tg/ewm_suc.png);
  background-size: cover;
}
</style>
