<template>
  <div class="box" v-show="this.$store.state.dialogVisible" >
     <h1 class="title">关于我们</h1>
 <p class="content">玩家驿站是成都精诚智达网络科技有限公司开发的专业的正版游戏销售平台，用户可在平台内，购买游戏账号、CDK或游戏周边产品，如用户购买的是共享服务，可直接通过平台启动游戏。平台售卖游戏包括Steam、Uplay、Origin、战网等海外网游联机平台游戏。目前，玩家驿站已经为百万玩家提供服务。作为一家专业的游戏综合服务商，玩家驿站致力于重塑并优化产业生态及服务形式，专注研发行业应用和链接业内游戏资源。玩家驿站会不断进化，提供更有竞争力的服务，为所有热爱游戏的玩家带来更爽快的体验。
</p>
 <p class="closetext" @click="closefn">关闭</p>
  </div>
</template>

<script>
export default {
  name: 'dia',
  data() {
    return {
       
    };
  },
 
  methods:{
    closefn(){
      this.$store.state.dialogVisible=false;
    }
   
     
  },
}
</script>
<style scoped lang='less'>
.box{
     position:absolute;
    border: 1px solid #aaa;
    background-color: #1a1a1a;
    width: 800px;
    height: 400px;
    top:200px;
    left: 500px;
    display: flex;
    flex-direction: column;
     align-items: center;
     color: #fff;
     font-family:"Microsoft YaHei UI",Arial,"Times New Roman";
   .content{
       font-size: 20px;
       text-align: start;
       width: 700px;
       height: 300px;
       
   }
   .closetext{
     font-size: 20px;
     color: yellow;
     cursor: pointer;
     text-decoration:underline;
    
   }
}

</style>
