<template>
  <div class="Cbox" @click="goto">
   
  </div>
</template>

<script>
export default {
  name: 'cusservice',
  data() {
    return {
    };
  },
 
  methods:{
    goto(){        
        window.open("http://wpa.qq.com/msgrd?v=3&uin=2796588134&site=qq&menu=yes", "_blank");
    },
  },
}
</script>
<style scoped lang='less'>
.Cbox{
    width: 58px;
    height: 58px;
    background: #ff00ff;
    background: url(~@/assets/images/new/kf.png);
    position: fixed;
    right: 57px;
    bottom: 280px;
    cursor: pointer;
}
.Cbox:hover{
    background-position: -58px 0px;
}
</style>
