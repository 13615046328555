<template>
  <div class="box">
    <div class="bg">
      <BdpAYyX ref="windowyx" :gameinfo="gameinfo" :channelNumber='channelNumber'></BdpAYyX>
      <BdpAYhY ref="windowhy" :channelNumber='channelNumber'></BdpAYhY>
      <div class="top_box">
        <div class="gotomain" @click="gotoMain"></div>
        <div class="download" @click="downloadFile"></div>
      </div>
      <div class="price_box">
        <div class="now_price">
          <span class="sp1">￥</span>
          <span class="sp2">{{ gameinfo.price }}</span>
        </div>
        <div class="y_price">
          <span class="sp1">优惠65%</span>
          <span class="sp2">原价：￥510</span>
        </div>
      </div>
      <div class="button_box">
           <div class="hy" @click="buyHYfn"></div>
           <div class="buy" @click="buyfn"></div>
      </div>
    </div>
  </div>
</template>

<script>
import BdpAYyX from "@/components/bd_pay_yx";
import BdpAYhY from "@/components/bd_pay_hy";
import { xb_getProInfo,addDown } from "@/service/api";
export default {
  components: {
    BdpAYyX,
    BdpAYhY,
  },
  data() {
    return {
      gameinfo: {
        name: "暗黑破坏神4",
        banben: "标准版",
        price: 999.99,
        id: 999,
        pic: "diablo.png",
      },
      channelNumber:"107"
      
    };
  },
  created() {},
  mounted() {
    this.getproinfo();
  },
  methods: {
    gotoMain() {
      const routeData = this.$router.resolve({ path: "/" });
      window.open(routeData.href, "_blank");
    },
    downFile(file) {
      let obj = {};
      obj = {
        url: file.url,
        name: file.filename,
      };
      return obj;
    },
    downloadFile() {
      const fileUrl = this.$store.state.downfile_diablo_bd.url;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute(
        "download",  
        "this.$store.state.downfile_diablo_bd.filename"
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.addDownfun()
    },
    async addDownfun(){
       let p={
         type:2,
         gameName:'百度暗黑破坏神'
       }
       const res = await addDown(p)
       
       if(res.code==200){
       }
       else{
       }
    },
    async getproinfo() {
      const data = await xb_getProInfo({
        product_id: 4346,
        origin: "ybjsqapi01",
      });
      if (data.status_code == 1000) {
        //成功
        this.gameinfo.name = data.data.product_name;
        this.gameinfo.id = data.data.product_id;
        this.gameinfo.price = data.data.standard_price.group.price;
      }
    },
   
    buyfn() {
      if (!this.$store.state.buywindowHY) {
        this.$store.state.buywindow = true;
        this.$refs.windowyx.init_created();
      }
    },
    buyHYfn() {
      if (!this.$store.state.buywindow) {
        this.$store.state.buywindowHY = true;
        this.$refs.windowhy.init_created();
      }
    },
  },
};
</script>
<style scoped lang="less">
.box {
  position: absolute;
  background-color: #000;
  width: 1920px;
  height: 2767px;
  top: 0px;
  background: url(~@/assets/images/bd/s1/bg.png);
  .bg {
    width: 100%;
    height: 940px;
    display: flex;
    flex-direction: column;
    .top_box {
      margin-top: 16px;
      display: flex;
      .gotomain {
        width: 254px;
        height: 34px;
        background: url(~@/assets/images/bd/s1/gotomain.png);
        cursor: pointer;
        margin-left: 331px;
        // border: 1px solid red;
      }
      .gotomain:hover {
        background-position: 254px 0;
      }
      .download {
        width: 201px;
        height: 66px;
        background: url(~@/assets/images/bd/s1/download.png);
        cursor: pointer;
        margin-left: 700px;
        // border: 1px solid red;
        background-position: -4px 0;
      }
      .download:hover {
        background-position: 203px 0;
      }
    }
    .price_box {
      // border: 1px solid red;
      margin-top: 220px;
      .now_price {
        .sp1 {
          color: #ffb823;
          font-size: 40px;
        }
        .sp2 {
          color: #ffb823;
          font-size: 60px;
        }
      }
      .y_price{
        color: #fff;
       
        .sp2{
          padding-left: 10px;
          text-decoration-line: line-through;
        }
      }
    }
    .button_box{
      display: flex;
      margin-top: 180px;
      .hy{
        width: 320px;
        height: 154px;
        background: url(~@/assets/images/bd/s1/hy.png);
        cursor: pointer;
        margin-left: 331px;
        
        background-position: -13px 0;
      }
      .hy:hover{
        background-position: 334px 0;
      }
      .buy{
        width: 320px;
        height: 154px;
        background: url(~@/assets/images/bd/s1/buy.png);
        cursor: pointer;
        margin-left: 20px;
        // border: 1px solid red;
        background-position: -13px 0;
      }
      .buy:hover{
        background-position: 334px 0;
      }
      
    }
  }
}
</style>
