import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/main/index.vue'
import download from '../views/download/index.vue'
import UserAgreement from '../views/UserAgreement/index.vue'
import GXUserAgreement from '../views/GXUserAgreement/index.vue'
import aboutUs from '../views/aboutUs/index.vue'
import HYUserAgreement from '../views/HYUserAgreement/index.vue'
import Diablo_bd from '../views/bd/diablo.vue'
import Pubg_bd from '../views/bd/pubg.vue'

import Pubg_bd_tg from '../views/bd_tg/pubg.vue'
import Pubg_bd_tg_hy from '../views/bd_tg/huiyuan.vue'
import Pubg_360_tg from '../views/360_tg/pubg.vue'
import Pubg_360_tg_hy from '../views/360_tg/huiyuan.vue'
import index_bd from '../views/main/index_bd.vue'
import index_360 from '../views/main/index_360.vue'
// import download_129 from '../views/download/index_bd.vue'
// import download_130 from '../views/download/index_360.vue'
import test from '../views/test/index.vue'



import Diablo_360 from '../views/360/diablo.vue'
import Pubg_360 from '../views/360/pubg.vue'
import Diablo_bd_p from '../views/bd/diablo_plus.vue'
import store from '../store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    meta:{
      title:'玩家驿站'
    }
  },
  // {
  //   path: '/129',
  //   name: 'index_bd',
  //   component: index_bd,
  //   meta:{
  //     title:'玩家驿站'
  //   }
  // },
  // {
  //   path: '/130',
  //   name: 'index_360',
  //   component: index_360,
  //   meta:{
  //     title:'玩家驿站'
  //   }
  // },
  {
    path: '/download',
    name: 'download',
    component: download,
    meta:{
      title:'下载'
    }
  },
  // {
  //   path: '/download_129',
  //   name: 'download',
  //   component: download_129,
  //   meta:{
  //     title:'下载'
  //   }
  // },
  // {
  //   path: '/download_130',
  //   name: 'download',
  //   component: download_130,
  //   meta:{
  //     title:'下载'
  //   }
  // },

  {
    path: '/UserAgreement',
    name: 'UserAgreement',
    component: UserAgreement,
    meta:{
      title:'用户协议'
    }
  },
  {
    path: '/GXUserAgreement',
    name: 'GXUserAgreement',
    component: GXUserAgreement,
    meta:{
      title:'共享游戏协议'
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs,
    meta:{
      title:'关于我们'
    }
  },
  {
    path: '/HYUserAgreement',
    name: 'HYUserAgreement',
    component: HYUserAgreement,
    meta:{
      title:'会员服务协议'
    }
  },
  {
    path: '/diablo_bd',
    name: 'Diablo',
    component: Diablo_bd,
    meta:{
      title:'暗黑破坏神'
    }
  },
  {
    path: '/pubg_bd',
    name: 'Pubg',
    component: Pubg_bd,
    meta:{
      title:'Pubg'
    }
  },
  {
    path: '/diablo_360',
    name: 'Diablo',
    component: Diablo_360,
    meta:{
      title:'暗黑破坏神'
    }
  },
  {
    path: '/pubg_360',
    name: 'Pubg',
    component: Pubg_360,
    meta:{
      title:'Pubg'
    }
  },
  {
    path: '/diablo_bd_p',
    name: 'Diablo',
    component: Diablo_bd_p,
    meta:{
      title:'暗黑破坏神(改)'
    }
  },
  {
    path: '/bd_tg',
    name: 'shy_bd',
    component: Pubg_bd_tg,
    meta:{
      title:'推广页'
    }
  },
  {
    path: '/bd_tg_hy',
    name: 'shy_hy_bd',
    component: Pubg_bd_tg_hy,
    meta:{
      title:'推广页'
    }
  },
  {
    path: '/360_tg',
    name: 'shy_360',
    component: Pubg_360_tg,
    meta:{
      title:'推广页'
    }
  },
  {
    path: '/360_tg_hy',
    name: 'shy_hy_360',
    component: Pubg_360_tg_hy,
    meta:{
      title:'推广页'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    if(to.meta.title=="暗黑破坏神" || to.meta.title=="Pubg" || to.meta.title=="Gta5" || to.meta.title=="推广页" || to.meta.title=="暗黑破坏神(改)"){
      store.state.com_show.Top=false
      store.state.com_show.FooterNav=false
      store.state.com_show.Login=false
      store.state.com_show.Cusservice=false
    }
    else{
      store.state.com_show.Top=true
      store.state.com_show.FooterNav=true
      store.state.com_show.Login=true
      store.state.com_show.Cusservice=true
    }
    document.title = to.meta.title
  }
  next()
})

export default router
