<template>
  <div class="buywindow" v-show="$store.state.buywindow">
    <div class="top">
      <div class="top_title">确定订单</div>
      <div @click="closefn" class="top_close"></div>
    </div>
    <div class="item_box">
      <div class="item_pic">
        <el-image
          style="width: 385px; height: 226px"
          :src="require('@/assets/images/bd/' + gameinfo.pic + '')"
          fit="cover"
        ></el-image>
      </div>
      <div class="item_info">
        <div class="guide_box">
          <div class="f1"></div>
          <div class="shu"></div>
          <div class="f2"></div>
          <div class="shu"></div>
        </div>
        <div class="info_box">
          <div class="t_1 tt_1">选择类型</div>
          <div class="t_2 tt_2">{{ gameinfo.name }}</div>
          <div class="tt_3">
            <span class="t_3">价格:</span>
            <span style="margin-left: 12px; font-size: 18px; color: #ffc54b"
              >￥</span
            >
            <span style="margin-left: 12px; font-size: 18px; color: #ffc54b">{{
              gameinfo.price
            }}</span>
            <span
              style="
                margin-left: 12px;
                font-size: 16px;
                color: #666;
                text-decoration: line-through;
              "
              >原价￥580</span
            >
          </div>
          <div class="tt_4">
            <span class="t_3">版本:</span>
            <span class="t_4" style="margin-left: 12px">标准</span>
          </div>
          <div class="tt_4">
            <span class="t_3">类型:</span>
            <span class="t_4" style="margin-left: 12px">共享账号</span>
          </div>
          <div class="t_1 tt_5">绑定手机</div>
          <div class="phone_box" >
            <div class="title">绑定手机号:</div>
            <div class="in_box">
              <el-input
                class="input_box"
                maxLength="11"
                v-model="ruleForm.phone"
                placeholder="请输入手机号"
                oninput="if(isNaN(value)) { value = null }"
                :disabled="login_flag"
              ></el-input>
            </div>
          </div>
          <div class="code_box" :class="{code_box_hide:login_flag}">
            <div class="title">填写验证码:</div>
            <div class="in_box">
              <el-input
                class="input_box"
                maxLength="6"
                v-model="ruleForm.code"
                placeholder="验证码"
                oninput="if(isNaN(value)) { value = null }"
                :disabled="login_flag"
              ></el-input>
              <span  @click="sendCode" class="send_code" v-show="!login_flag">{{ codename }}</span>
            </div>
          </div>
          <div class="error_box">{{ error_code }}</div>
          <div @click="loginfn" class="login_btn" v-show="!login_flag"></div>
        </div>
      </div>
    </div>
    <!-- 支付模块 -->
    <div v-show="login_flag" class="pay_box">
      <div class="pay_box_left">
        <div
          @click="pay_fun(0)"
          class="pay_wx"
          :class="{ pay_0: pay_type == 1 }"
        >
          <div class="icon"></div>
        </div>
        <div class="heng"></div>
        <div
          @click="pay_fun(1)"
          class="pay_zfb"
          :class="{ pay_0: pay_type == 0 }"
        >
          <div class="icon"></div>
        </div>
      </div>
      <div class="pay_pic">
        <vue-qr
          :text="paymentImg"
          :margin="8"
          :size="102"
          colorDark="#000"
          colorLight="#fff"
        ></vue-qr>
        <div
          class="ewm_hide"
          v-show="pay_times == 0 && !pay_success_flag"
          @click="refres_ewm"
        ></div>
        <div class="ewm_suc" v-show="pay_times == 0 && pay_success_flag"></div>
      </div>
      <div class="pay_info">
        <div class="pay_info_1">
          <span style="font-size: 12px; color: #b0b0b0">应付金额:</span>
          <span style="margin-left: 12px; font-size: 12px; color: #ffc54b"
            >￥</span
          >
          <span style="margin-left: 2px; font-size: 18px; color: #ffc54b">{{
            gameinfo.price
          }}</span>
        </div>
        <div class="pay_info_2">
          <div class="i_1"></div>
          <div class="i_2"></div>
          <div style="font-size: 12px; color: #b0b0b0">
            使用支付宝/微信扫码支付
          </div>
        </div>
        <div class="pay_info_3">
          <div class="pay_info_3_check"></div>
          <div @click="goto" class="pay_info_3_text">
            购买即代表同意游戏共享协议
          </div>
        </div>
      </div>
    </div>
    <div class="box_16" v-show="login_flag"></div>
  </div>
</template>

<script>
import {
  getPhoneCode,
  login,
  xb_create_order,
  xb_order_status,
} from "@/service/api";
import VueQr from "vue-qr";
export default {
  name: "bd_pay_yx",
  components: {
    VueQr,
  },
  props: {
    gameinfo: Object,
    channelNumber: String,
  },
  data() {
    return {
      pay_type: 0, //0是微信  1是支付宝
      login_flag: false, //是否绑定了手机号
      ruleForm: {
        phone: "",
        code: "",
      },
      
      send_code_flag: false,
      codename: "发送验证码",
      error_code: "",
      //定时器相关
      times: 0,
      timer: null,

      paymentImg: "",
      //支付定时器
      pay_times: 1,
      pay_timer: null,
      pay_success_flag: false,
    };
  },
  methods: {
    init_created() {
      if (localStorage.getItem("token")) {
        // this.login_flag = true;
        // this.init_fun();
      }
    },
    init_fun() {
      // this.ruleForm.phone = "";
      this.ruleForm.code = "";
      this.paymentImg = "";
      this.pay_clearInterval();
      this.create_order();
    },
    refres_ewm() {
      this.pay_clearInterval();
      this.create_order();
    },
    async create_order() {
      this.pay_success_flag = false;
      this.paymentImg = "";
      let params = {
        ch_type: 0,
        pay_type: this.pay_type,
        channel_number: this.channelNumber,
        game_amount: this.gameinfo.price,
        game_id: this.gameinfo.id,
      };
      const data = await xb_create_order(params);
      // console.log(data)
      if (data.base.code == 200) {
        let orderSn = data.data.orderSn;
        this.paymentImg = data.data.url.ret;
        this.paySuccess(this.get_order_status, orderSn);
      }
    },
    paySuccess(getStatusFn, order_sn) {
      this.pay_times = 60;
      this.pay_timer = setInterval(() => {
        if (this.pay_times != 0) {
          this.pay_times = this.pay_times - 1;
          getStatusFn(order_sn);
        } else {
          this.pay_clearInterval();
        }
      }, 1000);
    },
    pay_clearInterval() {
      if (this.pay_timer) {
        clearInterval(this.pay_timer);
        this.pay_timer = null;
        this.pay_times = 0;
      }
    },
    //查看充值是否成功
    async get_order_status(order_sn) {
      let p = {
        order_sn: order_sn,
      };
      const data = await xb_order_status(p);
      // console.log(data);
      if (data.base.code === 200 && data.data.status == 1) {
        // this.$store.dispatch("showMessageboxforever", "充值成功");
        this.pay_success_flag = true;
        this.pay_clearInterval();
      }
    },
    pay_fun(val) {
      if (this.pay_type != val) {
        this.pay_type = val;
        // console.log(this.pay_type);
        this.pay_clearInterval();
        this.create_order();
      }
    },
    goto() {
      const routeData = this.$router.resolve({ path: "/GXUserAgreement" });
      window.open(routeData.href, "_blank");
    },
    closefn() {
      this.$store.state.buywindow = false;
    },
    //登录
    async loginfn() {
      if (this.ruleForm.phone != "" && this.ruleForm.code != "") {
        this.error_code = "";
        const data = await login({
          phone: this.ruleForm.phone,
          code: this.ruleForm.code,
          channelNumber: this.channelNumber,
          type: 0,
        });
        if (data.code === 200) {
          let token = data.data.token;
          if (token.substring(0, 3) != "gw:") {
            token = "gw:" + token;
          }
          localStorage.setItem("token", token);
          // console.log(token);
          this.login_flag = true;
          // this.ruleForm.phone = "";
          // this.ruleForm.code = "";
          this.init_fun();
        } else {
          this.error_code = "验证码错误！";
        }
      } else {
        this.error_code = "*手机号或验证码不能为空";
      }
    },
    //发送验证码
    async sendCode() {
      if(this.login_flag){
        return
      }
      if (!this.send_code_flag) {
        if (this.ruleForm.phone != "") {
          const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
          if (!reg.test(this.ruleForm.phone)) {
            this.error_code = "*请输入正确的手机号";
          } else {
            const data = await getPhoneCode({
              phone: this.ruleForm.phone,
            });
            this.send_code_flag = true;
            this.error_code = "";
            this.times = 60;
            this.codename = "已发送(" + this.times + ")";
            this.timer = setInterval(() => {
              if (this.times != 0) {
                this.times = this.times - 1;
                this.codename = "已发送(" + this.times + ")";
              } else {
                this.clearInterval();
              }
            }, 1000);
            //console.log(data)
            if (data.code === 200) {
            }
          }
        } else {
          this.error_code = "*手机号不能为空";
        }
      }
    },
    clearInterval() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
        this.codename = "发送验证码";
        this.send_code_flag = false;
      }
    },
  },
  directives: {
    drag(el) {
      let oDiv = el; // 当前元素

      // let self = this // 上下文
      // 禁止选择网页上的文字
      document.onselectstart = function () {
        return false;
      };
      oDiv.onmousedown = function (e) {
        // console.log(e.target.className)
        //如果是顶部的div 再移动
        if (e.target.className == "top") {
          // 鼠标按下，计算当前元素距离可视区的距离
          let disX = e.clientX - oDiv.offsetLeft;
          let disY = e.clientY - oDiv.offsetTop;
          document.onmousemove = function (e) {
            // 通过事件委托，计算移动的距离
            let l = e.clientX - disX;
            let t = e.clientY - disY;
            // 移动当前元素
            oDiv.style.left = l + "px";
            oDiv.style.top = t + "px";
          };
          document.onmouseup = function (e) {
            document.onmousemove = null;
            document.onmouseup = null;
          };
          // return false不加的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
          return false;
        }
      };
    },
  },
};
</script>
<style scoped lang="less">
.buywindow {
  width: 870px;
  //   height: 473px;
  background-color: #000;
  z-index: 9999;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .top {
    width: 100%;
    height: 58px;
    background-color: #292a2e;
    display: flex;
    align-items: center;
    .top_title {
      font-size: 24px;
      font-weight: bold;
      color: #fff;
      margin-left: 24px;
    }
    .top_close {
      width: 20px;
      height: 20px;
      background: url(~@/assets/images/bd/close.png);
      margin-left: auto;
      margin-right: 17px;
      cursor: pointer;
    }
    .top_close:hover {
      background: url(~@/assets/images/bd/close_1.png);
    }
  }
  .item_box {
    width: 100%;
    height: 400px;
    background-color: #000;
    margin-top: 8px;
    display: flex;
    align-items: center;
    // justify-content: center;
    // border: 1px solid red;
    .item_pic {
      width: 346px;
      height: 400px;
    }
    .item_info {
      width: 524px;
      height: 400px;
      background-color: #000;
      //   border: 1px solid red;
      display: flex;
      .guide_box {
        width: 24px;
        height: 380px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 16px;
        .f1 {
          width: 24px;
          height: 24px;
          background: url(~@/assets/images/bd/f1.png);
        }
        .shu {
          margin-top: 8px;
          width: 1px;
          height: 145px;
          border-left: 1px dotted #fff;
        }
        .f2 {
          margin-top: 8px;
          width: 24px;
          height: 24px;
          background: url(~@/assets/images/bd/f2.png);
        }
      }
      .info_box {
        width: 450px;
        height: 100%;
        margin-left: 17px;
        // border: 1px solid red;
        display: flex;
        flex-direction: column;

        .t_1 {
          color: #e0e0e0;
          font-size: 18px;
        }
        .t_2 {
          color: #fff;
          font-size: 24px;
        }
        .t_3 {
          color: #e0e0e0;
          font-size: 16px;
        }
        .t_4 {
          color: #fff;
          font-size: 16px;
        }
        .tt_1 {
          margin-right: auto;
        }
        .tt_2 {
          margin-right: auto;
          margin-top: 4px;
        }
        .tt_3 {
          margin-right: auto;
          margin-top: 18px;
        }
        .tt_4 {
          margin-right: auto;
          margin-top: 12px;
        }
        .tt_5 {
          margin-right: auto;
          margin-top: 16px;
        }
        .phone_box {
          width: 341px;
          height: 34px;
          margin-right: auto;
          //   border: 1px solid red;
          margin-top: 8px;
          display: flex;
          align-items: center;
          .title {
            color: #e0e0e0;
            font-size: 16px;
          }
          .in_box {
            cursor: pointer;
            margin-left: 12px;
            width: 240px;
            height: 100%;
            background-color: #1c1d21;
            border-radius: 5px;
            border: 1px solid #7e7e7e;
            display: flex;
            .input_box {
              margin-left: 15px;
              margin-top: 5px;
            }
            /deep/.el-input__inner {
              height: 20px;
              width: 215px;
              background: #1c1d21;
              border: none;
              font-size: 16px;
              color: #d4d4d4;
            }
          }
          .in_box:hover {
            border: 1px solid #5dfe97;
          }
        }
        .code_box_hide{
          opacity: 50%;
          
        }
        .code_box {
          
          width: 341px;
          height: 34px;
          margin-right: auto;
          //   border: 1px solid red;
          margin-top: 16px;
          display: flex;
          align-items: center;
          .title {
            color: #e0e0e0;
            font-size: 16px;
          }
          .in_box {
            cursor: pointer;
            margin-left: 12px;
            width: 240px;
            height: 100%;
            background-color: #1c1d21;
            border-radius: 5px;
            border: 1px solid #7e7e7e;
            display: flex;
            align-items: center;
            .input_box {
              margin-left: 15px;
              margin-top: 5px;
            }
            /deep/.el-input__inner {
              height: 20px;
              width: 120px;
              background: #1c1d21;
              border: none;
              font-size: 16px;
              color: #d4d4d4;
              //   border: 1px solid red;
            }
            .send_code {
              font-size: 14px;
              color: #ffb823;
              cursor: pointer;
              height: 20px;
              margin-top: 5px;
              margin-left: 15px;
            }
            .send_code:hover {
              color: #9dffc1;
            }
          }
          .in_box:hover {
            border: 1px solid #5dfe97;
          }
        }
       
        .login_btn {
          width: 254px;
          height: 40px;
          background: url(~@/assets/images/bd/login_btn.png);
          cursor: pointer;
          margin-top: 32px;
          margin-left: 96px;
        }
        .login_btn:hover {
          background-position: 254px 0;
        }
        .error_box {
          position: absolute;
          top: 375px;
          left: 500px;
          font-size: 12px;
          color: #ff5c5c;
          text-align: start;
        }
      }
    }
  }
  .box_16 {
    width: 100%;
    height: 16px;
    background-color: #000;
  }
  .pay_box {
    width: 778px;
    height: 120px;
    background-color: #343539;
    margin-left: 46px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    .pay_box_left {
      width: 160px;
      height: 120px;
      //   border: 1px solid red;
      display: flex;
      flex-direction: column;
      .pay_wx {
        width: 100%;
        height: 58px;
        background-color: #48494c;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          width: 42px;
          height: 14px;
          background: url(~@/assets/images/bd/wx_1.png);
        }
      }
      .heng {
        width: 160px;
        height: 4px;
        background-color: #fff;
      }
      .pay_zfb {
        width: 100%;
        height: 58px;
        background-color: #48494c;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          width: 55px;
          height: 14px;
          background: url(~@/assets/images/bd/zfb_1.png);
        }
      }
      .pay_0 {
        background-color: #3f4043;
      }
    }
    .pay_pic {
      width: 102px;
      height: 102px;
      background-color: #fff;
      margin-left: 40px;
      .shadow {
        color: #000;
        .ss {
          text-decoration: underline;
          color: red;
          cursor: pointer;
        }
      }
    }
    .ewm_hide {
      position: relative;
      top: -106px;
      width: 102px;
      height: 102px;
      background: url(~@/assets/images/bd/ewm.png);
      cursor: pointer;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .ewm_suc {
      position: relative;
      top: -106px;
      width: 102px;
      height: 102px;
      background: url(~@/assets/images/bd/ewm_suc.png);
      cursor: pointer;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .pay_info {
      flex: 1;
      height: 120px;
      //   border: 1px solid red;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 30px;
      .pay_info_1 {
        display: flex;
      }
      .pay_info_2 {
        display: flex;
        align-items: center;
        .i_1 {
          width: 13px;
          height: 12px;
          background: url(~@/assets/images/bd/i_1.png);
        }
        .i_2 {
          width: 14px;
          height: 12px;
          background: url(~@/assets/images/bd/i_2.png);
        }
      }
      .pay_info_3 {
        display: flex;
        .pay_info_3_check {
          width: 18px;
          height: 18px;
          background: url(~@/assets/images/bd/check.png);
        }
        .pay_info_3_text {
          font-size: 12px;
          color: #b0b0b0;
          cursor: pointer;
          margin-left: 5px;
        }
        .pay_info_3_text:hover {
          color: #fff;
        }
      }
    }
  }
}
</style>
