<template>
  <div class="box">
    <div class="ttt">
      <!-- <vue-qr
        :text="paymentImg"
        :margin="8"
        :size="102"
        colorDark="#000"
        colorLight="#fff"
      ></vue-qr> -->
      <img class="img1" :src="paymentImg" >
    </div>
  </div>
</template>

<script>
import { getQrCode, WXlogin } from "@/service/api";
import VueQr from "vue-qr";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      paymentImg: "",
      qrcodeTicket:'',
      pay_times:0,
      pay_timer:null,

    };
  },
  created() {
    this.getQrCode_fun();
  },

  methods: {
    async getQrCode_fun() {
      let p = {
        uuId: "1234567",
      };
      const res = await getQrCode(p);
      console.log(res);
      this.paymentImg =
        "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=" + res.data.ticket;
        this.qrcodeTicket=res.data.ticket;
      console.log(this.paymentImg);
      this.paySuccess(this.get_order_status, this.qrcodeTicket);
    },
    paySuccess(getStatusFn, order_sn) {
      this.pay_times = 60;
      this.pay_timer = setInterval(() => {
        if (this.pay_times != 0) {
          this.pay_times = this.pay_times - 1;
          getStatusFn(order_sn);
        } else {
          this.pay_clearInterval();
        }
      }, 1000);
    },
    pay_clearInterval() {
      if (this.pay_timer) {
        clearInterval(this.pay_timer);
        this.pay_timer = null;
        this.pay_times = 0;
      }
    },
    //查看充值是否成功
    async get_order_status(order_sn) {
      let p = {
        qrcodeTicket: this.qrcodeTicket,
      };
      const data = await WXlogin(p);
      // console.log(data);
      if (data.code === 200 ) {
         console.log('登录成功')
        this.pay_success_flag = true;
        this.pay_clearInterval();
      }
    },
  },
};
</script>
<style scoped lang="less">
.box {
  background-color: #1a1a1a;
  width: 100%;
  height: 830px;
  margin: 0 auto;
  .ttt {
    width: 120px;
    height: 120px;
    border: 1px solid red;
    margin: 0 auto;
  }
}
</style>
