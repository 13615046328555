<template>
  <div class="box">
    <div class="header">
      <div class="h_top">
        <div class="t_title" @click="gotoshouye()" style="margin-left: 60px">
          首页
        </div>
        <div class="t_title" @click="gotowjyz()" style="margin-left: 44px">
          游戏中心
        </div>
        <div class="t_title" style="margin-left: 44px; color: #fff">
          会员中心
        </div>
        <div class="t_title" style="margin-left: 1150px" @click="kefufn()">联系客服</div>
        <div class="login_ico" style="margin-left: 44px"></div>
        <div class="t_title" style="margin-left: 6px" @click="gotowjyz_login()">
          登录
        </div>
        <div
          class="download"
          @click="downloadFile"
          style="margin-left: auto; margin-right: 60px"
        ></div>
      </div>
      <div class="hy_top_bg"></div>
      <div class="hy_sell_box">
        <div class="hy_1 hy_item">
          <div class="hy_title">三年会员</div>
          <div class="info_box">
            <div class="info_1">限时折扣价￥</div>
            <div class="info_2">{{ hy_Tyear_data.price }}</div>
            <div class="info_4"></div>
            <div class="info_3">原价899</div>
          </div>
          <div class="hy_btn_extra"></div>
          <div class="buy_btn" @click="hy_buy_fn(13)">立即开通</div>
          <div class="hy_zp"></div>
        </div>
        <div class="hy_2 hy_item">
          <div class="hy_title">年卡会员</div>
          <div class="info_box">
            <div class="info_1">限时折扣价￥</div>
            <div class="info_2">{{ hy_year_data.price }}</div>
            <div class="info_4"></div>
            <div class="info_3">原价290</div>
          </div>
          <div class="buy_btn_1" @click="hy_buy_fn(12)">立即开通</div>
          <div class="hy_zp"></div>
        </div>
        <div class="hy_3 hy_item">
          <div class="hy_title">月卡会员</div>
          <div class="info_box">
            <div class="info_1">限时折扣价￥</div>
            <div class="info_2">{{ hy_month_data.price }}</div>
            <div class="info_4">/月</div>
            <div class="info_3">原价899</div>
          </div>
          <div class="buy_btn_2" @click="hy_buy_fn(11)">立即开通</div>
          <div class="hy_zp_1"></div>
        </div>
      </div>
      <div class="hy_qy_title"></div>
      <div class="hy_qy_box"></div>
      <div class="hy_buy_help_title"></div>
      <div class="hy_help_box_1"></div>
      <div class="w1" style="margin-top: 24px">
        会员共享账号是共享一个账号吗?
      </div>
      <div class="d1" style="margin-top: 8px">
        会员并非是一个账号不变,会员可以全场游戏畅玩,但是账号是所有用户共享的哦
      </div>
      <div class="w1" style="margin-top: 24px">会员价与折扣价有什么区别?</div>
      <div class="d1" style="margin-top: 8px">
        折扣价是给全平台用户的优惠,会员价是只有会员才可以额外享受的专属优惠,两种优惠是可以叠加使用的
      </div>
      <div class="w1" style="margin-top: 24px">开通会员后是否有隐形消费?</div>
      <div class="d1" style="margin-top: 8px">
        会员一旦开通,即可畅玩全部共享游戏,不会有任何的隐形消费
      </div>
      <div class="zhanwei"></div>
    </div>

    <!-- 购买弹窗 -->
    <div class="buy_hy_windows" v-show="buy_hy_windows">
      <div class="closebtn" @click="closefn()"></div>
      <div class="w_title">开通大会员全场游戏免费玩</div>
      <div class="w_hy_bg">
        <div class="item_name">{{ current_data.productName }}会员</div>
        <div class="item_info">
          <div class="info_1">限时折扣价￥</div>
          <div class="info_2">{{ current_data.price }}</div>
          <div class="info_4"></div>
          <div class="info_3">原价899</div>
        </div>
      </div>
      <div class="title_hh">为了保障您的会员权益,请先绑定手机号</div>
      <div class="suc_text" v-show="login_flag">
        手机号{{ ruleForm.phone }}绑定成功!
      </div>
      <div class="phone_box" v-show="!login_flag">
        <div class="phone_title">手机号:</div>
        <div class="phone">
          <el-input
            class="input_box"
            maxLength="11"
            v-model="ruleForm.phone"
            placeholder="请输入手机号"
            oninput="if(isNaN(value)) { value = null }"
          ></el-input>
        </div>
        <div class="yzm_title">验证码:</div>
        <div class="yzm_code">
          <div class="code_box">
            <el-input
              class="input_box"
              maxLength="6"
              v-model="ruleForm.code"
              placeholder="请输入验证码"
              oninput="if(isNaN(value)) { value = null }"
            ></el-input>
          </div>
          <div class="send_code_text" @click="sendCode()">{{ codename }}</div>
        </div>
        <div class="phone_confirm" @click="loginfn()">确认</div>
      </div>
      <!-- 支付部分 -->
      <div class="pay_box">
        <div class="pay_type_box">
          <div
            class="zfb_type"
            @click="pay_fun(1)"
            :class="{ type_on: pay_type == 1 }"
          >
            <div class="t_1">
              <div class="zfb_ico"></div>
              <div class="zfb_text">支付宝</div>
            </div>
            <div v-show="pay_type == 1" class="down_line"></div>
          </div>
          <div
            class="wx_type"
            @click="pay_fun(0)"
            :class="{ type_on: pay_type == 0 }"
          >
            <div class="t_2">
              <div class="wx_ico"></div>
              <div class="wx_text">微信</div>
            </div>
            <div v-show="pay_type == 0" class="down_line"></div>
          </div>
        </div>
        <div class="ewm_box">
          <vue-qr
            :text="paymentImg"
            :margin="8"
            :size="100"
            colorDark="#000"
            colorLight="#fff"
          ></vue-qr>
          <div class="login_suc_pic" v-show="!login_flag" @click="show_e_c()">
            <div class="l_p"></div>
          </div>
          <div class="error_code" v-show="error_code_show">
            {{ error_code }}
          </div>
          <div
            class="ewm_hide"
            v-show="pay_times <= 1 && !pay_success_flag"
            @click="refres_ewm"
          ></div>
          <div class="ewm_suc" v-show="pay_success_flag"></div>
        </div>
        <div class="pay_info">
          <div class="pay_info_top">
            <div class="pay_info_top_1">应付金额:</div>
            <div class="pay_info_top_2">￥{{ current_data.price }}</div>
          </div>
          <div class="pay_info_m"></div>
          <div class="pay_info_b">
            <div class="pay_info_b_1">购买即代表阅读并同意</div>
            <div class="pay_info_b_2" @click="goto()">《游戏服务协议》</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import {
  xb_getHYInfo,
  login,
  getPhoneCode,
  xb_create_order,
  xb_order_status,
   addDown 
} from "@/service/api";
export default {
  data() {
    return {
      hy_month_data: { id: 0, productName: "", price: 999 },
      hy_year_data: { id: 0, productName: "", price: 999 },
      hy_Tyear_data: { id: 0, productName: "", price: 999 },
      buy_hy_windows: false,
      current_data: {},
      ruleForm: {
        phone: "",
        code: "",
      },
      paymentImg: "",
      pay_type: 1, //0是微信  1是支付宝
      //是否绑定了手机号
      login_flag: false,
      error_code_show: false,
      //发送验证码
      send_code_flag: false,
      codename: "发送验证码",
      error_code: "*请先绑定手机号！",
      //验证码定时器相关
      times: 0,
      timer: null,
      //支付定时器
      pay_times: 0,
      pay_timer: null,
      pay_success_flag: false,
      channelNumber:'122',
    };
  },
  components: {
    VueQr,
  },
  created() {},
  mounted() {
    this.get_xb_getHYInfo();
  },
  methods: {
    async create_order() {
      if (this.login_flag) {
        this.pay_success_flag = false;
        this.paymentImg = "";
        let params = {
          ch_type: 0,
          pay_type: this.pay_type,
          channel_number: this.channelNumber,
          product_amount: this.current_data.price,
          product_id: this.current_data.id,
        };
        const data = await xb_create_order(params);
        // console.log(data)
        if (data.base.code == 200) {
          let orderSn = data.data.orderSn;
          this.paymentImg = data.data.url.ret;
          this.paySuccess(this.get_order_status, orderSn);
        }
      }
    },
    //查看充值是否成功
    async get_order_status(order_sn) {
      let p = {
        order_sn: order_sn,
      };
      const data = await xb_order_status(p);
      // console.log(data);
      if (data.base.code === 200 && data.data.status == 1) {
        // this.$store.dispatch("showMessageboxforever", "充值成功");
        this.pay_success_flag = true;
        this.pay_clearInterval();
      }
    },
    paySuccess(getStatusFn, order_sn) {
      this.pay_times = 180;
      this.pay_timer = setInterval(() => {
        if (this.pay_times != 0) {
          this.pay_times = this.pay_times - 1;
          getStatusFn(order_sn);
        } else {
          this.pay_clearInterval();
        }
      }, 1000);
    },
    pay_clearInterval() {
      if (this.pay_timer) {
        clearInterval(this.pay_timer);
        this.pay_timer = null;
        this.pay_times = 0;
      }
    },
    //发送验证码
    async sendCode() {
      this.error_code_show = false;
      if (!this.send_code_flag) {
        if (this.ruleForm.phone != "") {
          const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
          if (!reg.test(this.ruleForm.phone)) {
            this.error_code = "*请输入正确的手机号";
            this.error_code_show = true;
          } else {
            const data = await getPhoneCode({
              phone: this.ruleForm.phone,
            });
            this.send_code_flag = true;
            this.error_code = "";
            this.times = 60;
            this.codename = "已发送(" + this.times + ")";
            this.timer = setInterval(() => {
              if (this.times != 0) {
                this.times = this.times - 1;
                this.codename = "已发送(" + this.times + ")";
              } else {
                this.clearInterval();
              }
            }, 1000);
            //console.log(data)
            if (data.code === 200) {
            }
          }
        } else {
          this.error_code = "*手机号不能为空";
          this.error_code_show = true;
        }
      }
    },
    clearInterval() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
        this.codename = "发送验证码";
        this.send_code_flag = false;
      }
    },
    //登录
    async loginfn() {
      if (this.ruleForm.phone != "" && this.ruleForm.code != "") {
        this.error_code = "";
        this.error_code_show = false;
        const data = await login({
          phone: this.ruleForm.phone,
          code: this.ruleForm.code,
          channelNumber: this.channelNumber,
          type: 0,
        });
        if (data.code === 200) {
          let token = data.data.token;
          if (token.substring(0, 3) != "gw:") {
            token = "gw:" + token;
          }
          localStorage.setItem("token", token);
          localStorage.setItem("t_phone", this.ruleForm.phone);
          // console.log(token);
          this.login_flag = true;

          this.error_code_show = false;
          this.pay_clearInterval();
          this.create_order();
        } else {
          this.error_code = "验证码错误！";
          this.error_code_show = true;
        }
      } else {
        this.error_code = "*手机号或验证码不能为空";
        this.error_code_show = true;
      }
    },
    //购买相应的会员 限时弹窗
    hy_buy_fn(id_index) {
      if (this.buy_hy_windows) {
        return;
      } else {
        this.buy_hy_windows = true;
        this.current_data = {};
        if (id_index == 11) {
          this.current_data = this.hy_month_data;
        }
        if (id_index == 12) {
          this.current_data = this.hy_year_data;
        }
        if (id_index == 13) {
          this.current_data = this.hy_Tyear_data;
        }
        if (localStorage.getItem("token") && localStorage.getItem("t_phone")){
          this.ruleForm.phone=localStorage.getItem("t_phone")
          this.login_flag = true;
        }
        this.pay_clearInterval();
        this.create_order();
      }
    },
    //关闭弹窗
    closefn() {
      this.buy_hy_windows = false;
      //重置支付信息
      this.paymentImg = "";
      this.pay_type = 1;
      this.error_code_show = false;
      this.pay_clearInterval();
    },
    //获取大会员xinxi
    async get_xb_getHYInfo() {
      const data = await xb_getHYInfo({
        p_type: 1,
        platform_id: 2,
      });
      if (data.base.code == 200) {
        let hy_data = [...data.data];
        if (hy_data.length != 0) {
          hy_data.forEach((item) => {
            // console.log(item)
            if (item.id == 11) {
              this.hy_month_data.id = item.id;
              this.hy_month_data.productName = item.productName;
              this.hy_month_data.price = item.productPrice;
            }
            if (item.id == 12) {
              this.hy_year_data.id = item.id;
              this.hy_year_data.productName = item.productName;
              this.hy_year_data.price = item.productPrice;
            }
            if (item.id == 13) {
              this.hy_Tyear_data.id = item.id;
              this.hy_Tyear_data.productName = item.productName;
              this.hy_Tyear_data.price = item.productPrice;
            }
          });
        } else {
          console.log("获取会员信息失败");
        }
      }
    },
    downloadFile() {
      const fileUrl = this.$store.state.downfile_bd_list[5].url;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute(
        "download",
        "this.$store.state.downfile_diablo_bd.filename"
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.addDownfun();
    },
     async addDownfun() {
      let p = {
        type: 2,
        gameName: "百度_会员",
      };
      const res = await addDown(p);

      if (res.code == 200) {
      } else {
      }
    },
    gotoshouye() {
      this.$router.push("/bd_tg");
    },
    gotowjyz_login() {
      this.$store.state.isloginshow = true;
      this.$router.push("/");
    },
    gotowjyz() {
      const routeData = this.$router.resolve({ path: "/" });
      window.open(routeData.href, "_blank");
    },
    refres_ewm() {
      this.pay_clearInterval();
      this.create_order();
    },
    goto() {
      const routeData = this.$router.resolve({ path: "/GXUserAgreement" });
      window.open(routeData.href, "_blank");
    },
    //提示先绑定手机号
    show_e_c() {
      this.error_code_show = true;
      this.error_code = "*请先绑定手机号！";
      setTimeout(() => {
        this.error_code_show = false;
      }, 5000); // 定时时间
    },
    kefufn(){
      window.open("http://wpa.qq.com/msgrd?v=3&uin=2796588134&site=qq&menu=yes", "_blank");
    },
    pay_fun(val) {
      if (this.pay_type != val) {
        this.pay_type = val;
        // console.log(this.pay_type);
        this.pay_clearInterval();
        this.create_order();
      }
    },
  },
};
</script>
<style scoped lang="less">
.box {
  position: absolute;
  background-color: #000;
  width: 1920px;
  //   height: 2767px;
  // left: 50%;
  // transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  .header {
    // height: 1080px;
    width: 100%;
    display: flex;
    flex-direction: column;
    // border: 1px solid red;
    .h_top {
      height: 92px;
      width: 100%;
      display: flex;
      align-items: center;
      //   border: 1px solid red;
      .t_title {
        font-size: 20px;
        color: #aaa;
        cursor: pointer;
      }
      .t_title:hover {
        color: yellow;
      }
      .login_ico {
        width: 16px;
        height: 16px;
        background: url(~@/assets/images/bd_tg/login_ico.png);
      }
      .download {
        width: 112px;
        height: 40px;
        background: url(~@/assets/images/bd_tg/download.png);
        cursor: pointer;
      }
    }
    .hy_top_bg {
      margin: 0 auto;
      width: 1476px;
      height: 280px;
      background: url(~@/assets/images/bd_tg/hy_top_bg.png);
      background-size: cover;
    }
    .hy_sell_box {
      margin: 0 auto;
      margin-top: 44px;
      width: 1476px;
      height: 220px;
      // border: 1px solid red;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .hy_1 {
        background: url(~@/assets/images/bd_tg/hy_1.png);
      }
      .hy_2 {
        background: url(~@/assets/images/bd_tg/hy_2.png);
      }
      .hy_3 {
        background: url(~@/assets/images/bd_tg/hy_3.png);
      }
      .hy_item {
        // margin-left: 33px;
        width: 385px;
        height: 220px;

        background-size: cover;

        display: flex;
        flex-direction: column;
        .hy_title {
          width: 100%;
          color: #2e3548;
          font-size: 28px;
          margin-top: 18px;
        }
        .info_box {
          width: 100%;
          display: flex;
          margin-top: 24px;
          .info_1 {
            margin-left: 104px;
            color: #2e3548;
            font-size: 12px;
            display: flex;
            line-height: 46px;
          }
          .info_2 {
            color: #000;
            font-size: 30px;
          }
          .info_3 {
            margin-left: 12px;
            color: #aaa;
            font-size: 12px;
            text-decoration: line-through;
            line-height: 46px;
          }
          .info_4 {
            color: #2e3548;
            font-size: 12px;
            display: flex;
            line-height: 46px;
          }
        }
        .hy_btn_extra {
          margin-top: 1px;
          width: 128px;
          height: 19px;
          background: url(~@/assets/images/bd_tg/hy_btn_extra.png);
          background-size: cover;
          margin-left: 110px;
        }
        .buy_btn {
          cursor: pointer;
          background: #f6d5a9;
          width: 176px;
          height: 34px;
          border-radius: 5px;
          line-height: 34px;
          font-size: 16px;
          color: #2e3548;
          margin: 0 auto;
        }
        .buy_btn:hover {
          background: #f1bd7f;
        }
        .buy_btn_1 {
          cursor: pointer;
          background: #f1ca7f;
          width: 176px;
          height: 34px;
          border-radius: 5px;
          line-height: 34px;
          font-size: 16px;
          color: #2e3548;
          margin: 0 auto;
          margin-top: 20px;
        }
        .buy_btn_1:hover {
          background: #f9e9c1;
        }
        .buy_btn_2 {
          cursor: pointer;
          background: #f1b67f;
          width: 176px;
          height: 34px;
          border-radius: 5px;
          line-height: 34px;
          font-size: 16px;
          color: #000;
          margin: 0 auto;
          margin-top: 20px;
        }
        .buy_btn_2:hover {
          background: #f9e9c1;
        }
        .hy_zp {
          width: 365px;
          height: 22px;
          background: url(~@/assets/images/bd_tg/hy_zp.png);
          background-size: cover;
          margin: 0 auto;
          margin-top: auto;
          margin-bottom: 10px;
        }
        .hy_zp_1 {
          width: 139px;
          height: 22px;
          background: url(~@/assets/images/bd_tg/hy_zp_1.png);
          background-size: cover;
          margin: 0 auto;
          margin-top: auto;
          margin-bottom: 10px;
        }
      }
    }
    .hy_qy_title {
      width: 760px;
      height: 39px;
      margin: 0 auto;
      margin-top: 65px;
      background: url(~@/assets/images/bd_tg/hy_qy_title.png);
      background-size: cover;
    }
    .hy_qy_box {
      width: 1476px;
      height: 80px;
      margin: 0 auto;
      margin-top: 40px;
      background: url(~@/assets/images/bd_tg/hy_qy_box.png);
      background-size: cover;
    }
    .hy_buy_help_title {
      width: 1476px;
      height: 80px;
      margin: 0 auto;
      margin-top: 44px;
      background: url(~@/assets/images/bd_tg/hy_buy_help_title.png);
      background-size: cover;
    }
    .hy_help_box_1 {
      width: 1476px;
      height: 260px;
      margin: 0 auto;
      margin-top: 24px;
      background: url(~@/assets/images/bd_tg/hy_help_box_1.png);
      background-size: cover;
    }
    .w1 {
      color: #fff;
      font-size: 24px;
      width: 1360px;
      margin: 0 auto;
      text-align: start;
    }
    .d1 {
      color: #ccc;
      font-size: 20px;
      width: 1360px;
      margin: 0 auto;
      text-align: start;
    }

    .zhanwei {
      width: 100%;
      height: 110px;
      border: 1px solid #000;
    }
  }
  .buy_hy_windows {
    width: 870px;
    height: 490px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #000;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #aaa;
    .closebtn {
      width: 20px;
      height: 20px;
      background: url(~@/assets/images/bd_tg/closebtn.png);
      background-size: cover;
      margin-top: 20px;
      margin-left: auto;
      margin-right: 50px;
      cursor: pointer;
    }
    .closebtn:hover {
      opacity: 0.8;
    }
    .w_title {
      font-size: 24px;
      color: #fff;
      margin-top: 15px;
    }
    .w_hy_bg {
      width: 778px;
      height: 80px;
      background: url(~@/assets/images/bd_tg/w_hy_bg.png);
      background-size: cover;
      margin: 0 auto;
      margin-top: 30px;
      display: flex;
      align-items: center;
      .item_name {
        font-size: 28px;
        color: #000;
        font-weight: bold;
        margin-left: 170px;
        // border: 1px solid red;
      }
      .item_info {
        display: flex;
        margin-left: 145px;
        margin-top: 18px;
        // border: 1px solid red;
        .info_1 {
          color: #2e3548;
          font-size: 12px;
          display: flex;
          line-height: 46px;
        }
        .info_2 {
          color: #000;
          font-size: 30px;
        }
        .info_3 {
          margin-left: 12px;
          color: #aaa;
          font-size: 12px;
          text-decoration: line-through;
          line-height: 46px;
        }
        .info_4 {
          color: #2e3548;
          font-size: 12px;
          display: flex;
          line-height: 46px;
        }
      }
    }
    .title_hh {
      font-size: 18px;
      color: #e0e0e0;
      margin-top: 24px;
      text-align: start;
      padding-left: 50px;
    }
    .suc_text {
      margin-top: 16px;
      color: greenyellow;
      font-size: 24px;
    }
    .phone_box {
      margin-top: 16px;
      width: 778px;
      height: 44px;
      margin-left: 50px;
      // border: 1px solid red;
      display: flex;
      align-items: center;
      .phone_title {
        font-size: 16px;
        color: #ccc;
        line-height: 44px;
      }
      .phone {
        margin-left: 12px;
        width: 244px;
        height: 34px;
        border-radius: 5px;
        //   border: 1px solid red;
        background: #1c1d21;
        .input_box {
          margin-top: 5px;
        }
        /deep/.el-input__inner {
          height: 25px;
          width: 200px;
          background: #1c1d21;
          border: none;
          font-size: 16px;
          // font-family: PingFangSC-Regular, PingFang SC;
          color: #d4d4d4;
        }
      }

      .yzm_title {
        font-size: 16px;
        color: #ccc;
        line-height: 44px;
        margin-left: 16px;
      }
      .yzm_code {
        margin-left: 12px;
        width: 244px;
        height: 34px;
        border-radius: 5px;
        // border: 1px solid red;
        background: #1c1d21;
        display: flex;
        .code_box {
          width: 144px;
          height: 34px;
          // border: 1px solid red;
          .input_box {
            margin-top: 5px;
          }
          /deep/.el-input__inner {
            height: 25px;
            width: 100px;
            background: #1c1d21;
            border: none;
            font-size: 16px;
            // font-family: PingFangSC-Regular, PingFang SC;
            color: #d4d4d4;
          }
        }
        .send_code_text {
          width: 100px;
          height: 34px;
          font-size: 16px;
          color: #ffb823;
          line-height: 34px;
          cursor: pointer;
        }
        .send_code_text:hover {
          color: #fff;
        }
        .input_box {
          margin-top: 5px;
        }
      }
      .phone_confirm {
        width: 52px;
        height: 28px;
        background: #3b3b3b;
        border-radius: 5px;
        color: #fff;
        line-height: 28px;
        font-size: 14px;
        margin-left: 16px;
        cursor: pointer;
      }
      .phone_confirm:hover {
        background: #666;
      }
    }
    .pay_box {
      margin-top: 40px;
      width: 778px;
      height: 120px;
      margin-left: 50px;
      // border: 1px solid red;
      border-radius: 5px;
      display: flex;
      background: #333;
      .pay_type_box {
        width: 160px;
        height: 100%;
        display: flex;
        flex-direction: column;
        .type_on {
          background: #3f4043;
        }
        .zfb_type {
          width: 100%;
          height: 60px;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          .t_1 {
            width: 100%;
            height: 58px;
            display: flex;
            align-items: center;
            .zfb_ico {
              margin-left: 54px;
              width: 12px;
              height: 12px;
              background: url(~@/assets/images/bd_tg/zfb.png);
              background-size: cover;
            }
            .zfb_text {
              margin-left: 6px;
              font-size: 12px;
              color: #fff;
            }
          }
          .down_line {
            width: 100%;
            height: 2px;
            background-color: #feecb8;
          }
        }
        .wx_type {
          width: 100%;
          height: 60px;
          display: flex;
          flex-direction: column;
          cursor: pointer;
          .t_2 {
            width: 100%;
            height: 58px;
            display: flex;
            align-items: center;
            .wx_ico {
              margin-left: 54px;
              width: 12px;
              height: 12px;
              background: url(~@/assets/images/bd_tg/wx.png);
              background-size: cover;
            }
            .wx_text {
              margin-left: 6px;
              font-size: 12px;
              color: #fff;
            }
          }
          .down_line {
            width: 100%;
            height: 2px;
            background-color: #feecb8;
          }
        }
      }
      .ewm_box {
        width: 102px;
        height: 102px;
        // border: 1px solid red;
        margin-left: 40px;
        margin-top: 9px;
      }
      .pay_info {
        margin-left: 16px;
        width: 262px;
        height: 100%;
        // border: 1px solid red;
        display: flex;
        flex-direction: column;
        .pay_info_top {
          margin-top: 30px;
          width: 100%;
          display: flex;
          .pay_info_top_1 {
            font-size: 14px;
            color: #dedede;
          }
          .pay_info_top_2 {
            margin-left: 17px;
            font-size: 14px;
            color: #ffb823;
          }
          .pay_info_top_3 {
            font-size: 14px;
            color: #dedede;
            margin-left: auto;
            margin-right: 16px;
            text-decoration: line-through;
          }
        }
        .pay_info_m {
          margin-top: 5px;
          width: 446px;
          height: 24px;
          background: url(~@/assets/images/bd_tg/hy_hh.png);
          background-size: cover;
        }
        .pay_info_b {
          margin-top: 10px;
          display: flex;
          .pay_info_b_1 {
            font-size: 12px;
            color: #dedede;
          }
          .pay_info_b_2 {
            font-size: 12px;
            color: #29aefc;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.login_suc_pic {
  position: relative;
  top: -105px;
  width: 102px;
  height: 102px;
  background: #000;
  opacity: 0.8;
  display: flex;
  align-items: center;
  cursor: pointer;
  .l_p {
    margin: 0 auto;
    width: 44px;
    height: 44px;
    background: url(~@/assets/images/bd_tg/login_suc.png);
    background-size: cover;
  }
}
.error_code {
  position: relative;
  top: -335px;
  left: 150px;
  width: 200px;
  font-size: 16px;
  color: red;
}
.ewm_hide {
  position: relative;
  top: -105px;
  width: 102px;
  height: 102px;
  background: url(~@/assets/images/bd_tg/ewm.png);
  // background: red;
  cursor: pointer;
  background-size: cover;
}
.ewm_suc {
  position: relative;
  top: -105px;
  width: 102px;
  height: 102px;
  background: url(~@/assets/images/bd_tg/ewm_suc.png);
  background-size: cover;
}
</style>
