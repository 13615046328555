<template>
  <div class="box">
    <BdTgYx
      ref="windowyx_tg"
      :gameinfo="gameinfo"
      :channelNumber="channelNumber"
    ></BdTgYx>
    <div
      class="header bg_pubg"
      :class="{
        bg_pubg: game_id == 0,
        bg_gta: game_id == 1,
        bg_ard: game_id == 2,
        bg_2077: game_id == 3,
        bg_xk: game_id == 4,
      }"
    >
      <div class="h_top">
        <div class="t_title" style="margin-left: 60px; color: #fff">首页</div>
        <div class="t_title" @click="gotowjyz()" style="margin-left: 44px">
          游戏中心
        </div>
        <div class="t_title" @click="gotohuiyuan()" style="margin-left: 44px">
          会员中心
        </div>
        <div class="t_title" style="margin-left: 1150px" @click="kefufn()">
          联系客服
        </div>
        <div class="login_ico" style="margin-left: 44px"></div>
        <div class="t_title" style="margin-left: 6px" @click="gotowjyz_login()">
          登录
        </div>
        <div
          class="download"
          style="margin-left: auto; margin-right: 60px"
          @click="downloadFile"
        ></div>
      </div>
      <div class="mp4_box">
        <!-- <button @click="game_id = 0">pubg</button>
        <button @click="game_id = 1">gta5</button>
        <button @click="game_id = 2">艾尔登法环</button>
        <button @click="game_id = 3">赛博朋克2077</button>
        <button @click="game_id = 4">星空</button> -->
        <div class="view_box" v-html="videoHtml">
          <!-- <video :src="video_url"  autoplay style="width: 481px;height: 356px;" ></video> -->
        </div>
        <div class="right_index">
          <div
            class="r_i_1"
            @click="switch_game(0)"
            :class="{ r_i_on: game_id == 0 }"
          ></div>
          <div
            class="r_i_2"
            @click="switch_game(1)"
            :class="{ r_i_on: game_id == 1 }"
          ></div>
          <div
            class="r_i_3"
            @click="switch_game(2)"
            :class="{ r_i_on: game_id == 2 }"
          ></div>
          <div
            class="r_i_4"
            @click="switch_game(3)"
            :class="{ r_i_on: game_id == 3 }"
          ></div>
          <div
            class="r_i_5"
            @click="switch_game(4)"
            :class="{ r_i_on: game_id == 4 }"
          ></div>
        </div>
      </div>
      <div class="h_bottom">
        <div class="h_b_top">
          <div class="h_b_top_title"></div>
          <div class="price">￥{{ gameinfo.price }}</div>
          <div class="yy" v-show="game_id == 0">原价98</div>
          <div class="yy" v-show="game_id == 1">原价119</div>
          <div class="yy" v-show="game_id == 2">原价398</div>
          <div class="yy" v-show="game_id == 3">原价298</div>
          <div class="yy" v-show="game_id == 4">原价298</div>
        </div>
        <div class="h_b_line"></div>
        <div class="daojishi">
          <div class="djs_bg" style="width: 96px">距离降价结束</div>
          <!-- <div class="mh">:</div> -->
          <div class="djs_bg" style="width: 40px; margin-left: 16px">
            {{ buy_times.hour }}
          </div>
          <div class="mh">:</div>
          <div class="djs_bg" style="width: 40px; margin-left: 8px">
            {{ buy_times.min }}
          </div>
          <div class="mh">:</div>
          <div class="djs_bg" style="width: 40px; margin-left: 8px">
            {{ buy_times.seconds }}
          </div>
        </div>
        <div class="qyy"></div>
        <div class="ico_box">
          <div class="ico_1"></div>
          <div class="ico_2"></div>
        </div>
        <div class="button_box">
          <div class="button_1" @click="showbuyYXWindow()"></div>
          <div class="button_2" @click="gotohuiyuan()"></div>
        </div>
      </div>
    </div>
    <div class="bg_m">
      <div class="down_btn" @click="downloadFile"></div>
    </div>
    <div class="yxjs"></div>
    <div class="pic_box">
      <div
        class="pic_1"
        :class="{
          pic_1_pubg: game_id == 0,
          pic_1_gta: game_id == 1,
          pic_1_ard: game_id == 2,
          pic_1_2077: game_id == 3,
          pic_1_xk: game_id == 4,
        }"
      ></div>
      <div class="right_pic_box">
        <div class="right_top_pic_box">
          <div
            class="pic_2"
            :class="{
              pic_2_pubg: game_id == 0,
              pic_2_gta: game_id == 1,
              pic_2_ard: game_id == 2,
              pic_2_2077: game_id == 3,
              pic_2_xk: game_id == 4,
            }"
          ></div>
          <div
            class="pic_3"
            :class="{
              pic_3_pubg: game_id == 0,
              pic_3_gta: game_id == 1,
              pic_3_ard: game_id == 2,
              pic_3_2077: game_id == 3,
              pic_3_xk: game_id == 4,
            }"
          ></div>
        </div>
        <div class="right_bottom_pic_box">
          <div
            class="pic_4"
            :class="{
              pic_4_pubg: game_id == 0,
              pic_4_gta: game_id == 1,
              pic_4_ard: game_id == 2,
              pic_4_2077: game_id == 3,
              pic_4_xk: game_id == 4,
            }"
          ></div>
          <div
            class="pic_5"
            :class="{
              pic_5_pubg: game_id == 0,
              pic_5_gta: game_id == 1,
              pic_5_ard: game_id == 2,
              pic_5_2077: game_id == 3,
              pic_5_xk: game_id == 4,
            }"
          ></div>
        </div>
      </div>
    </div>
    <div class="bg_3"></div>
    <div class="bg_4"></div>
    <div class="hot_box">
      <div class="hot_t">
        <div class="hot_1"></div>
        <div class="hot_2"></div>
      </div>
      <div class="hot_b">
        <div class="hot_3"></div>
        <div class="hot_4"></div>
      </div>
    </div>
    <div class="d_b" @click="downloadFile"></div>
    <div class="zhanwei"></div>
  </div>
</template>

<script>
import BdTgYx from "@/components/bd_tg_yx";
import { xb_getProInfo, addDown } from "@/service/api";
export default {
  components: {
    BdTgYx,
  },
  data() {
    return {
      game_id: 0, //0 pubg  1 gta 2 ard 3 2077 4 xk
      gameinfo: {
        name: "绝地求生",
        price: 999.99,
        id: 999,
        extra_id: [],
        original_price: 0,
      },
      channelNumber: "117",
      //定时器相关
      times: 0,
      timer: null,
      buy_times: {
        hour: 48,
        min: 0,
        seconds: 0,
      },
      video_url: "",
      videoHtml: "",
    };
  },
  created() {},
  mounted() {
    let g_id = this.$route.query.id;
    if (g_id == 0 || g_id == 1 || g_id == 2 || g_id == 3 || g_id == 4) {
      this.switch_game(g_id);
    } else {
      this.video_url =
        "https://media.st.dl.eccdnx.com/steam/apps/256962888/movie480_vp9.webm?t=1691652637";
      this.videoHtml =
        "<video src='" +
        this.video_url +
        "'  autoplay style='width: 481px;height: 356px;' > </video>";
    }

    this.create_timer();
    this.getproinfo();
  },
  methods: {
    showbuyYXWindow() {
      this.channelNumber = this.$store.state.downfile_bd_list[
        this.game_id
      ].channelNumber;
      this.$store.state.buywindow_tg = true;
      this.$refs.windowyx_tg.init_created();
    },
    async getproinfo() {
      let pid = 3997;
      this.gameinfo.extra_id = [];
      if (this.game_id == 0) {
        pid = 3997;
        this.gameinfo.extra_id.push(3997);
        this.gameinfo.extra_id.push(4008);
        this.gameinfo.extra_id.push(1);
        this.gameinfo.original_price = 98;
      }
      if (this.game_id == 1) {
        pid = 2;
        this.gameinfo.extra_id.push(2);
        this.gameinfo.original_price = 119;
      }
      if (this.game_id == 2) {
        pid = 4011;
        this.gameinfo.extra_id.push(4011);
        this.gameinfo.original_price = 398;
      }
      if (this.game_id == 3) {
        pid = 1587;
        this.gameinfo.extra_id.push(1587);
        this.gameinfo.original_price = 298;
      }
      if (this.game_id == 4) {
        pid = 4380;
        this.gameinfo.extra_id.push(4380);
        this.gameinfo.extra_id.push(4381);
        this.gameinfo.original_price = 298;
      }
      const data = await xb_getProInfo({
        product_id: pid,
        origin: "ybjsqapi01",
      });
      // console.log(data)
      if (data.status_code == 1000) {
        //成功
        this.gameinfo.name = data.data.product_name;
        this.gameinfo.id = data.data.product_id;
        this.gameinfo.price = data.data.standard_price.group.price;
      }
      // console.log(data)
    },
    switch_game(val) {
      this.game_id = val;
      this.getproinfo();
      if (val == 0) {
        console.log("0");
        this.video_url =
          "https://media.st.dl.eccdnx.com/steam/apps/256962888/movie480_vp9.webm?t=1691652637";
        this.videoHtml =
          "<video src='" +
          this.video_url +
          "'  autoplay style='width: 481px;height: 356px;' > </video>";
      }
      if (val == 1) {
        console.log("1");
        this.video_url =
          "https://media.st.dl.eccdnx.com/steam/apps/256921446/movie480_vp9.webm?t=1671116313";
        this.videoHtml =
          "<video src='" +
          this.video_url +
          "'  autoplay style='width: 481px;height: 356px;' > </video>";
      }
      if (val == 2) {
        this.video_url =
          "https://media.st.dl.eccdnx.com/steam/apps/256867721/movie480_vp9.webm?t=1683070613";
        this.videoHtml =
          "<video src='" +
          this.video_url +
          "'  autoplay style='width: 481px;height: 356px;' > </video>";
      }
      if (val == 3) {
        this.video_url =
          "https://media.st.dl.eccdnx.com/steam/apps/256904576/movie480_vp9.webm?t=1662480328";
        this.videoHtml =
          "<video src='" +
          this.video_url +
          "'  autoplay style='width: 481px;height: 356px;' > </video>";
      }
      if (val == 4) {
        this.video_url =
          "https://media.st.dl.eccdnx.com/steam/apps/256954173/movie480_vp9.webm?t=1687290076";
        this.videoHtml =
          "<video src='" +
          this.video_url +
          "'  autoplay style='width: 481px;height: 356px;' > </video>";
      }
    },
    gotohuiyuan() {
      this.$router.push("/bd_tg_hy");
    },
    gotowjyz() {
      const routeData = this.$router.resolve({ path: "/" });
      window.open(routeData.href, "_blank");
    },
    create_timer() {
      if (localStorage.getItem("buy_times")) {
        this.times = localStorage.getItem("buy_times");
      } else {
        this.times = 172800;
      }
      this.timer = setInterval(() => {
        if (this.times != 0) {
          this.times = this.times - 1;
          localStorage.setItem("buy_times", this.times);
          let hour = Math.floor(this.times / 3600);
          if (hour < 10) {
            hour = "0" + hour;
          }
          this.buy_times.hour = hour;
          let min = Math.floor((this.times - hour * 3600) / 60);
          if (min < 10) {
            min = "0" + min;
          }
          this.buy_times.min = min;
          let seconds = this.times - hour * 3600 - min * 60;
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          this.buy_times.seconds = seconds;
        } else {
          this.clearInterval();
        }
      }, 1000);
    },
    clearInterval() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    gotowjyz_login() {
      this.$store.state.isloginshow = true;
      this.$router.push("/");
    },
    kefufn() {
      window.open(
        "http://wpa.qq.com/msgrd?v=3&uin=2796588134&site=qq&menu=yes",
        "_blank"
      );
    },
    downloadFile() {
      const fileUrl = this.$store.state.downfile_bd_list[this.game_id].url;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute(
        "download",
        "this.$store.state.downfile_bd_list[this.game_id].filename"
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.addDownfun();
    },
    async addDownfun() {
      let yx_name = "";
      if (this.game_id == 0) {
        yx_name = "pubg";
      }
      if (this.game_id == 1) {
        yx_name = "gta5";
      }
      if (this.game_id == 2) {
        yx_name = "艾尔登法环";
      }
      if (this.game_id == 3) {
        yx_name = "赛博朋克2077";
      }
      if (this.game_id == 4) {
        yx_name = "星空";
      }
      let name_val = "百度_" + yx_name;
      let p = {
        type: 2,
        gameName: name_val,
      };
      const res = await addDown(p);

      if (res.code == 200) {
      } else {
      }
    },
  },
};
</script>
<style scoped lang="less">
.box {
  position: absolute;
  background-color: #000;
  width: 1920px;
  //   height: 2767px;
  display: flex;
  flex-direction: column;
  .bg_pubg {
    background: url(~@/assets/images/bd_tg/pubg.png);
    background-size: cover;
  }
  .bg_gta {
    background: url(~@/assets/images/bd_tg/gta.png);
    background-size: cover;
  }
  .bg_ard {
    background: url(~@/assets/images/bd_tg/ard.png);
    background-size: cover;
  }
  .bg_2077 {
    background: url(~@/assets/images/bd_tg/2077.png);
    background-size: cover;
  }
  .bg_xk {
    background: url(~@/assets/images/bd_tg/xk.png);
    background-size: cover;
  }
  .header {
    height: 1080px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .h_top {
      height: 92px;
      width: 100%;
      display: flex;
      align-items: center;
      //   border: 1px solid red;
      .t_title {
        font-size: 20px;
        color: #aaa;
        cursor: pointer;
      }
      .t_title:hover {
        color: yellow;
      }
      .login_ico {
        width: 16px;
        height: 16px;
        background: url(~@/assets/images/bd_tg/login_ico.png);
      }
      .download {
        width: 112px;
        height: 40px;
        background: url(~@/assets/images/bd_tg/download.png);
        cursor: pointer;
      }
    }
    .mp4_box {
      height: 429px;
      width: 652px;
      margin-top: 40px;
      margin-left: 1046px;
      background: url(~@/assets/images/bd_tg/view_bg.png);
      background-size: cover;
      display: flex;
      .view_box {
        width: 481px;
        height: 356px;
        // border: 1px solid red;
        margin-left: 16px;
        margin-top: 49px;
        background: #000;
        .view_video {
          width: 481px;
          height: 356px;
        }
      }
      .right_index {
        width: 120px;
        height: 356px;
        //   border: 1px solid red;
        margin-top: 49px;
        margin-left: 22px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .r_i_1 {
          width: 120px;
          height: 68px;
          background: url(~@/assets/images/bd_tg/i_0.png);
          background-size: cover;
          cursor: pointer;
        }
        .r_i_2 {
          width: 120px;
          height: 68px;
          background: url(~@/assets/images/bd_tg/i_1.png);
          background-size: cover;
          cursor: pointer;
        }
        .r_i_3 {
          width: 120px;
          height: 68px;
          background: url(~@/assets/images/bd_tg/i_2.png);
          background-size: cover;
          cursor: pointer;
        }
        .r_i_4 {
          width: 120px;
          height: 68px;
          background: url(~@/assets/images/bd_tg/i_3.png);
          background-size: cover;
          cursor: pointer;
        }
        .r_i_5 {
          width: 120px;
          height: 68px;
          background: url(~@/assets/images/bd_tg/i_4.png);
          background-size: cover;
          cursor: pointer;
        }
        .r_i_on {
          border: 2px solid yellow;
        }
      }
    }
    .h_bottom {
      height: 500px;
      width: 488px;
      margin-top: 28px;
      //   border: 1px solid red;
      margin-left: auto;
      margin-right: 225px;
      display: flex;
      flex-direction: column;
      .h_b_top {
        width: 100%;
        // border: 1px solid red;
        display: flex;
        height: 61px;
        .h_b_top_title {
          width: 201px;
          height: 61px;
          background: url(~@/assets/images/bd_tg/2.png);
        }
        .price {
          font-size: 50px;
          color: #ffde00;
          font-weight: bold;
          margin-left: 20px;
          line-height: 61px;
        }
        .yy {
          color: #fff;
          font-size: 16px;
          margin-top: auto;
          margin-left: 16px;
          text-decoration-line: line-through;
        }
      }
      .h_b_line {
        width: 477px;
        height: 9px;
        background: url(~@/assets/images/bd_tg/1.png);
      }
    }
    .daojishi {
      width: 285px;
      height: 40px;
      //   border: 1px solid red;
      display: flex;
      margin-top: 18px;
      .djs_bg {
        height: 40px;
        background: #000;
        border-radius: 5px;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        line-height: 40px;
      }
      .mh {
        color: #000;
        font-size: 22px;
        margin-left: 8px;
        line-height: 40px;
        font-weight: bold;
      }
    }
    .qyy {
      margin-top: 80px;
      width: 395px;
      height: 28px;
      background: url(~@/assets/images/bd_tg/3.png);
    }
    .ico_box {
      width: 100%;
      height: 25px;
      display: flex;
      margin-top: 12px;
      .ico_1 {
        width: 118px;
        height: 25px;
        background: url(~@/assets/images/bd_tg/5.png);
      }
      .ico_2 {
        margin-left: 138px;
        width: 118px;
        height: 25px;
        background: url(~@/assets/images/bd_tg/6.png);
      }
    }
    .button_box {
      margin-top: -2px;
      width: 100%;
      height: 83px;
      display: flex;
      .button_1 {
        margin-left: 3px;
        width: 232px;
        height: 83px;
        background: url(~@/assets/images/bd_tg/7.png);
        cursor: pointer;
      }
      .button_1:hover {
        opacity: 0.8;
      }
      .button_2 {
        margin-left: 30px;
        width: 232px;
        height: 83px;
        background: url(~@/assets/images/bd_tg/8.png);
        cursor: pointer;
      }
      .button_2:hover {
        opacity: 0.8;
      }
    }
  }
  .bg_m {
    width: 1920px;
    height: 401px;
    background: url(~@/assets/images/bd_tg/bg_m.png);
    display: flex;
    .down_btn {
      width: 232px;
      height: 82px;
      // border: 1px  solid red;
      margin-top: 208px;
      margin-left: 1540px;
      cursor: pointer;
    }
    .down_btn:hover {
      background-color: #000;
      opacity: 0.2;
    }
  }
  .yxjs {
    margin-top: 60px;
    width: 1920px;
    height: 88px;
    background: url(~@/assets/images/bd_tg/yxjs.png);
  }
  .pic_box {
    // width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    height: 460px;
    // border: 1px solid red;
    display: flex;
    .pic_1 {
      width: 676px;
      height: 460px;

      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }
    .pic_1_pubg {
      background: url(~@/assets/images/bd_tg/pubg_1.png);
    }
    .pic_1_gta {
      background: url(~@/assets/images/bd_tg/gta_1.png);
    }
    .pic_1_ard {
      background: url(~@/assets/images/bd_tg/ard_1.png);
    }
    .pic_1_2077 {
      background: url(~@/assets/images/bd_tg/2077_1.png);
    }
    .pic_1_xk {
      background: url(~@/assets/images/bd_tg/xk_1.png);
    }
    .pic_1:hover {
      transform: scale(1.05);
    }
    .right_pic_box {
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      .right_top_pic_box {
        width: 100%;
        height: 214px;
        display: flex;
        .pic_2 {
          width: 326px;
          height: 214px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
        }
        .pic_2_pubg {
          background: url(~@/assets/images/bd_tg/pubg_2.png);
        }
        .pic_2_gta {
          background: url(~@/assets/images/bd_tg/gta_2.png);
        }
        .pic_2_ard {
          background: url(~@/assets/images/bd_tg/ard_2.png);
        }
        .pic_2_2077 {
          background: url(~@/assets/images/bd_tg/2077_2.png);
        }
        .pic_2_xk {
          background: url(~@/assets/images/bd_tg/xk_2.png);
        }
        .pic_2:hover {
          transform: scale(1.1);
        }
        .pic_3 {
          margin-left: 24px;
          width: 383px;
          height: 214px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
        }
        .pic_3_pubg {
          background: url(~@/assets/images/bd_tg/pubg_3.png);
        }
        .pic_3_gta {
          background: url(~@/assets/images/bd_tg/gta_3.png);
        }
        .pic_3_ard {
          background: url(~@/assets/images/bd_tg/ard_3.png);
        }
        .pic_3_2077 {
          background: url(~@/assets/images/bd_tg/2077_3.png);
        }
        .pic_3_xk {
          background: url(~@/assets/images/bd_tg/xk_3.png);
        }
        .pic_3:hover {
          transform: scale(1.1);
        }
      }
      .right_bottom_pic_box {
        width: 100%;
        height: 214px;
        display: flex;
        margin-top: 30px;
        .pic_4 {
          width: 382px;
          height: 214px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
        }
        .pic_4_pubg {
          background: url(~@/assets/images/bd_tg/pubg_4.png);
        }
        .pic_4_gta {
          background: url(~@/assets/images/bd_tg/gta_4.png);
        }
        .pic_4_ard {
          background: url(~@/assets/images/bd_tg/ard_4.png);
        }
        .pic_4_2077 {
          background: url(~@/assets/images/bd_tg/2077_4.png);
        }
        .pic_4_xk {
          background: url(~@/assets/images/bd_tg/xk_4.png);
        }
        .pic_4:hover {
          transform: scale(1.1);
        }
        .pic_5 {
          margin-left: 24px;
          width: 325px;
          height: 214px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
        }
        .pic_5_pubg {
          background: url(~@/assets/images/bd_tg/pubg_5.png);
        }
        .pic_5_gta {
          background: url(~@/assets/images/bd_tg/gta_5.png);
        }
        .pic_5_ard {
          background: url(~@/assets/images/bd_tg/ard_5.png);
        }
        .pic_5_2077 {
          background: url(~@/assets/images/bd_tg/2077_5.png);
        }
        .pic_5_xk {
          background: url(~@/assets/images/bd_tg/xk_5.png);
        }
        .pic_5:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  .bg_3 {
    margin-top: 80px;
    width: 1920px;
    height: 845px;
    background: url(~@/assets/images/bd_tg/bg_3.png);
  }
  .bg_4 {
    width: 1920px;
    height: 88px;
    background: url(~@/assets/images/bd_tg/bg_4.png);
  }
  .hot_box {
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    .hot_t {
      width: 100%;
      height: 460px;
      display: flex;
      .hot_1 {
        height: 460px;
        width: 676px;
        background: url(~@/assets/images/bd_tg/hot_1.png);
        cursor: pointer;
        background-size: cover;
      }
      .hot_2 {
        margin-left: 78px;
        height: 460px;
        width: 676px;
        background: url(~@/assets/images/bd_tg/hot_2.png);
        cursor: pointer;
        background-size: cover;
      }
    }
    .hot_b {
      margin-top: 40px;
      width: 100%;
      height: 460px;
      display: flex;
      .hot_3 {
        height: 460px;
        width: 676px;
        background: url(~@/assets/images/bd_tg/hot_3.png);
        cursor: pointer;
        background-size: cover;
      }
      .hot_4 {
        margin-left: 78px;
        height: 460px;
        width: 676px;
        background: url(~@/assets/images/bd_tg/hot_4.png);
        cursor: pointer;
        background-size: cover;
      }
    }
  }
  .d_b {
    margin: 0 auto;
    margin-top: 48px;
    width: 376px;
    height: 82px;
    background: url(~@/assets/images/bd_tg/d_b.png);
    cursor: pointer;
    background-size: cover;
  }
  .d_b:hover {
    opacity: 0.8;
  }
  .zhanwei {
    width: 100%;
    height: 110px;
    border: 1px solid #000;
  }
}
</style>
